import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  downloadFile(res): void {
    const fileParams = this.getFileParams(res);
    const downloadLink = document.createElement('a');

    downloadLink.href = fileParams.url;
    downloadLink.download = fileParams.fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  openFile(res) {
    const fileParams = this.getFileParams(res);
    const url = fileParams.url;
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.rel = 'noopener noreferrer'; // Security best practice
    anchor.click();
  }

  private getFileParams(res): { fileName: string; url: string } {
    const contentType = res.headers.get('content-type') || 'text/pdf; charset=utf-8';
    const contentDisposition = res.headers.get('Content-Disposition') || '';
    const parsedContent = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    const blob = new Blob([res.body], { type: contentType });

    return {
      fileName: (parsedContent != null && parsedContent[1] ? parsedContent[1] : 'file.pdf').trim(),
      url: window.URL.createObjectURL(blob),
    };
  }
}
