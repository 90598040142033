import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat',
  standalone: true,
})
export class PriceFormatTransformPipe implements PipeTransform {
  transform(value: number | string, showDifSign: boolean = false): string {
    if (!value) {
      return '0';
    }

    if (typeof value === 'string') {
      if (this.isNumeric(value)) {
        value = +value;
      } else {
        return value;
      }
    }

    const isNegative = value < 0;

    if (value > -1000 && value < 1000) {
      return this.addCurrencySign(value.toFixed(2).toString(), showDifSign, isNegative);
    }

    value = isNegative ? -value.toFixed(2) : value.toFixed(2);
    const minusSymbol = isNegative ? '-' : '';
    const tail = value.toString().split('.')[1] || 0;
    const integerPart = value.toString().split('.')[0];
    const formattedIntegerPart = integerPart
      .split('')
      .reverse()
      .reduce((str, number, index) => {
        if (!((index + 1) % 3) && index !== integerPart.length - 1) {
          str += number + ',';
        } else {
          str += number;
        }
        return str;
      }, '')
      .split('')
      .reverse()
      .join('');

    const res = tail ? minusSymbol + formattedIntegerPart + '.' + tail : minusSymbol + formattedIntegerPart;

    return this.addCurrencySign(res, showDifSign, isNegative);
  }

  isNumeric(str) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  addCurrencySign(str: string, showDifSign: boolean, isNegative: boolean) {
    let res: string;
    if (showDifSign) {
      const difSign = isNegative ? '-' : '+';
      res = difSign + '$' + Math.abs(+str).toString();
    } else {
      res = '$' + str;
    }
    return res;
  }
}
