import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpandableTextComponent } from '../expandable-text/expandable-text.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { PriceFormatTransformPipe } from '../../pipes/price-format.pipe';
import { NgClass } from '@angular/common';
import { IBenefit, IBenefitLevel, IPackage } from '../../models/application.interface';

@Component({
  selector: 'app-package-content',
  standalone: true,
  imports: [ExpandableTextComponent, MatIcon, MatIconButton, PriceFormatTransformPipe, NgClass],
  templateUrl: './package-content.component.html',
  styleUrl: './package-content.component.scss',
})
export class PackageContentComponent {
  selectedPackageData: IPackage;
  @Input() isReadOnly: boolean;
  @Input() set selectedPackage(value: IPackage) {
    if (value) {
      this.selectedPackageData = value;
      this.selectedPackageData.companies.forEach(company => {
        company.products.forEach(product => {
          product.benefits.forEach(benefit => {
            this.setSelectedBenefitLevel(benefit);
            if (benefit.levels.length === 1) {
              benefit.isOnlyOneLevelAvailable = true;
            }
          });
        });
      });
    }
  }
  @Output() benefitLevelChangeEvent: EventEmitter<IPackage> = new EventEmitter<IPackage>();
  constructor() {}

  setSelectedBenefitLevel(benefit: IBenefit) {
    benefit.selectedLevel = benefit.levels.find(item => item.isSelected);
  }

  onLevelOptionClick(benefit: IBenefit, level: IBenefitLevel) {
    benefit.selectedLevel.isSelected = false;
    level.isSelected = true;
    benefit.selectedLevel = level;
    this.benefitLevelChangeEvent.emit(this.selectedPackageData);
  }
}
