import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { AppRepository } from '../../shared/repositories/app.repository';
import { BehaviorSubject, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ApplicationView,
  ApplicationViewPayload,
  ICalculatePackagePayload,
  IPackage,
} from '../../shared/models/application.interface';
import { CustomerService } from '../../shared/services/customer.service';
import { StepperService } from '../../shared/services/stepper.service';
import { PackageRepository } from '../../shared/repositories/package.repository';
import { QuoteStepComponent } from './components/quote-step/quote-step.component';
import { InsurancePlanStepComponent } from './components/insurance-plan-step/insurance-plan-step.component';
import { BenefitsStepComponent } from './components/benefits-step/benefits-step.component';
import { find } from 'lodash-es';
import { EnrollmentStepComponent } from './components/enrollment-step/enrollment-step.component';
import { AppService } from '../../shared/services/app.service';
import { PlanStartDateSectionComponent } from '../../shared/components/plan-start-date-section/plan-start-date-section.component';
import { FormControl, Validators } from '@angular/forms';
import { startDateRangeValidation } from '../../shared/utils/custom-validators';
import { PurchaseStepComponent } from './components/purchase-step/purchase-step.component';
import { AgentRepository } from '../../shared/repositories/agent.repository';
import { convertStringToLocalDate } from '../../shared/utils/date-format';
import { PaymentMethodTypeEnum } from '../../shared/enums/paymentMethodType.enum';
import { ISendLinkData } from '../../shared/models/common.interface';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    QuoteStepComponent,
    InsurancePlanStepComponent,
    BenefitsStepComponent,
    EnrollmentStepComponent,
    PurchaseStepComponent,
    PlanStartDateSectionComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [DatePipe],
})
export class HomeComponent implements OnInit, OnDestroy {
  quote$: BehaviorSubject<ApplicationView> = new BehaviorSubject<ApplicationView>(null);
  packagesList$: BehaviorSubject<IPackage[]> = new BehaviorSubject<IPackage[]>([]);
  selectedPackage$: BehaviorSubject<IPackage> = new BehaviorSubject<IPackage>(null);
  serverData: ApplicationView;
  applicationId: string;
  isQuoteSaved: boolean = false;
  startDateControl: FormControl = new FormControl(null, [Validators.required, startDateRangeValidation()]);
  currentPaymentMethod: PaymentMethodTypeEnum;
  isListBillMode: boolean;
  destroy$ = new Subject<void>();
  constructor(
    private appRepository: AppRepository,
    private packageRepository: PackageRepository,
    private customerService: CustomerService,
    private stepperService: StepperService,
    private agentRepository: AgentRepository,
    private appService: AppService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.isListBillMode = this.customerService.isListBillModeActive();
    if (this.isListBillMode) {
      this.currentPaymentMethod = PaymentMethodTypeEnum.ACH;
    } else {
      this.currentPaymentMethod = PaymentMethodTypeEnum.CARD;
    }
    this.applicationId = this.customerService.getAppId();
    if (this.applicationId) {
      this.isQuoteSaved = true;
      this.appService.setUnsavedData(null);
      this.appRepository.getApplication(this.applicationId, true).subscribe(res => {
        this.setCurrentAppData(res, true);
        const currentAgent = this.customerService.getAgent();
        if (!currentAgent) {
          this.setAgentData(res);
        }
      });
    } else {
      this.stepperService.setInitialStep(0);
    }
  }

  setAgentData(quote: ApplicationView) {
    this.agentRepository.getAgentInfo(quote.agentId).subscribe(res => {
      this.customerService.setAgent(res);
      this.setParamsToUrl(quote);
    });
  }

  saveFirstStepData(eventData: { formData: ApplicationView; navigateToNextStep: boolean }) {
    if (eventData.formData.id) {
      if (!eventData.formData.selectedPackageId) {
        this.setSelectedPackage(null);
      }
      this.updateQuote(eventData.formData, eventData.navigateToNextStep ? 1 : null).subscribe();
    } else {
      this.createQuote(eventData.formData, eventData.navigateToNextStep ? 1 : null);
    }
  }

  signAgreementQuote(applicationData: ApplicationView): void {
    if (applicationData.id) {
      this.updateQuote(applicationData).subscribe();
    } else {
      this.createQuote(applicationData);
    }
  }

  updateQuote(applicationData: ApplicationView, stepToNavigate?: number): Observable<ApplicationView> {
    return new Observable(observer => {
      this.generateApplicationPayload(applicationData)
        .pipe(take(1))
        .subscribe(applicationDataPayload => {
          this.appRepository
            .updateQuote(applicationDataPayload, applicationData.id)
            .pipe(take(1))
            .subscribe(res => {
              this.setCurrentAppData(res);
              this.isQuoteSaved = true;
              this.appService.setUnsavedData(null);
              if (stepToNavigate) {
                this.stepperService.completeStep(stepToNavigate - 1);
              }
              observer.next(res);
              observer.complete();
            });
        });
    });
  }

  createQuote(applicationData: ApplicationView, stepToNavigate?: number): void {
    this.generateApplicationPayload(applicationData)
      .pipe(take(1))
      .subscribe(applicationDataPayload => {
        this.appRepository
          .createQuote(applicationDataPayload)
          .pipe(take(1))
          .subscribe(res => {
            this.setCurrentAppData(res);
            this.setParamsToUrl(res);
            this.isQuoteSaved = true;
            this.appService.setUnsavedData(null);
            if (stepToNavigate) {
              this.stepperService.completeStep(stepToNavigate - 1);
            }
          });
      });
  }

  goToEnrollmentStep(data: { email: string; startDate: string }) {
    if (this.isQuoteSaved) {
      this.quote$.pipe(take(1)).subscribe(quoteData => {
        this.updateQuote(quoteData, 3).subscribe();
      });
    } else {
      this.appService.saveQuoteWithSendLinkSubject$.next(data);
      this.stepperService.completeStep(2);
    }
  }

  saveQuoteWithSendLink(data: ISendLinkData, isShareQuoteAction: boolean): void {
    if (data.appData.id) {
      this.updateQuoteWithSendLink(data.appData, isShareQuoteAction, data.type);
    } else {
      this.createQuoteWithSendLink(data.appData, isShareQuoteAction, data.type);
    }
  }

  updateQuoteWithSendLink(
    applicationData: ApplicationView,
    isShareQuoteAction: boolean,
    sendType: 'email' | 'sms'
  ): void {
    if (this.customerService.isAgentModeActive() && !isShareQuoteAction) {
      this.updateQuote(applicationData).subscribe();
    } else {
      if (sendType === 'email') {
        this.appRepository
          .sendApplicationQuoteLinkByMail(applicationData.id)
          .pipe(take(1))
          .subscribe(() => {
            this.updateQuote(applicationData).subscribe();
          });
      } else if (sendType === 'sms') {
        this.updateQuote(applicationData).subscribe(() => {
          this.appRepository.sendApplicationQuoteLinkBySMS(applicationData.id).pipe(take(1)).subscribe();
        });
      }
    }
  }

  createQuoteWithSendLink(
    applicationData: ApplicationView,
    isShareQuoteAction: boolean,
    sendType: 'email' | 'sms'
  ): void {
    this.generateApplicationPayload(applicationData)
      .pipe(take(1))
      .subscribe(applicationDataPayload => {
        this.appRepository
          .createQuote(applicationDataPayload)
          .pipe(
            switchMap(res => {
              this.setCurrentAppData(res);
              this.setParamsToUrl(res);
              this.isQuoteSaved = true;
              this.appService.setUnsavedData(null);
              if (this.customerService.isAgentModeActive() && !isShareQuoteAction) {
                return of(null);
              } else {
                if (sendType === 'email') {
                  return this.appRepository.sendApplicationQuoteLinkByMail(res.id);
                } else {
                  return this.appRepository.sendApplicationQuoteLinkBySMS(res.id);
                }
              }
            }),
            take(1)
          )
          .subscribe();
      });
  }

  goToPackagesStep(quoteData: ApplicationView) {
    if (!this.isQuoteSaved) {
      this.setCurrentAppData(quoteData);
    }
    this.setPackagesList(quoteData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.stepperService.completeStep(0);
      });
  }

  setPackagesList(quoteData: ApplicationView): Observable<IPackage[]> {
    return new Observable(observer => {
      if (!quoteData?.packages?.length) {
        const payloadData: ICalculatePackagePayload = {
          agentId: quoteData.agentId,
          zipCode: quoteData.contactInfo.zipCode,
          startDate: quoteData.startDate,
          customers: quoteData.customers,
          includeCreditCardSurcharge: this.currentPaymentMethod === PaymentMethodTypeEnum.CARD,
          selectedBenefitIds: [],
          listBillId: this.isListBillMode ? this.customerService.getListBillId() : null,
        };
        this.packageRepository
          .calculatePackages(payloadData)
          .pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            this.packagesList$.next(res);
            observer.next(res);
            observer.complete();
          });
      } else {
        this.packagesList$.next(quoteData.packages);
        observer.next(quoteData.packages);
        observer.complete();
      }
    });
  }

  setSelectedPackage(packageItem: IPackage) {
    if (packageItem) {
      packageItem.totalPrice = this.appService.generatePackageTotalPrice(packageItem, this.isListBillMode);
    }
    this.selectedPackage$.next(packageItem);
  }

  setParamsToUrl(applicationData: ApplicationView) {
    const currentUrl = new URL(location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    searchParams.set('agentId', applicationData.agentId);
    searchParams.set('applicationId', applicationData.id);
    const newUrl = currentUrl.origin + currentUrl.pathname + '?' + searchParams.toString();
    history.pushState({}, '', newUrl);
  }

  setCurrentAppData(applicationData: ApplicationView, isInitialCall: boolean = false): void {
    if (applicationData) {
      this.customerService.setAppId(applicationData.id);
      this.serverData = applicationData;
      this.startDateControl.setValue(convertStringToLocalDate(applicationData.startDate));
      this.quote$.next(applicationData.payment ? { ...applicationData, isPaymentSubmitted: true } : applicationData);
      if (applicationData.selectedPackageId && applicationData.packages.length) {
        this.setPackagesList(applicationData)
          .pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            const selectedPackage = find(res, item => item.id === applicationData.selectedPackageId);
            this.setSelectedPackage(selectedPackage);
          });
      }
      if (applicationData.payment?.paymentType) {
        this.setCurrentPaymentMethod(applicationData.payment.paymentType);
      }
      if (isInitialCall) {
        this.stepperService.setInitialStep(applicationData.step);
      }
    }
  }

  setCurrentPaymentMethod(method: PaymentMethodTypeEnum) {
    this.currentPaymentMethod = method;
  }

  onSelectPackage(packageItem: IPackage) {
    this.quote$.pipe(take(1)).subscribe(quoteData => {
      if (this.isQuoteSaved) {
        let updatedQuoteData = { ...quoteData, selectedPackageId: packageItem.id };
        if (this.serverData?.signatures?.length) {
          updatedQuoteData = this.appService.setEmptySignature(updatedQuoteData);
        }
        this.setSelectedPackage(packageItem);
        this.updateQuote(updatedQuoteData, 2).subscribe();
      } else {
        this.quote$.next({ ...quoteData, selectedPackageId: packageItem.id });
        this.setSelectedPackage(packageItem);
        this.stepperService.completeStep(1);
      }
    });
  }

  onBenefitLevelChange(packageItem: IPackage) {
    const selectedBenefitIds: string[] = this.appService.generateSelectedBenefitIds(packageItem);
    this.quote$.pipe(take(1)).subscribe(quoteData => {
      const payloadData: ICalculatePackagePayload = {
        agentId: quoteData.agentId,
        zipCode: quoteData.contactInfo.zipCode,
        startDate: quoteData.startDate,
        customers: quoteData.customers,
        includeCreditCardSurcharge: this.currentPaymentMethod === PaymentMethodTypeEnum.CARD,
        selectedBenefitIds: selectedBenefitIds,
        listBillId: this.isListBillMode ? this.customerService.getListBillId() : null,
      };

      if (this.serverData?.signatures?.length) {
        const updatedQuoteData = this.appService.setEmptySignature(this.serverData);
        this.generateApplicationPayload(updatedQuoteData)
          .pipe(take(1))
          .subscribe(appPayload => {
            this.appRepository
              .updateQuote(appPayload, updatedQuoteData.id)
              .pipe(
                switchMap(res => {
                  this.setCurrentAppData(res);
                  return this.packageRepository.calculatePackage(packageItem.id, payloadData);
                }),
                takeUntil(this.destroy$)
              )
              .subscribe(packageItem => {
                this.updateSelectedPackage(packageItem);
              });
          });
      } else {
        this.packageRepository
          .calculatePackage(packageItem.id, payloadData)
          .pipe(takeUntil(this.destroy$))
          .subscribe(packageItem => {
            this.updateSelectedPackage(packageItem);
          });
      }
    });
  }

  updateSelectedPackage(packageItem: IPackage): void {
    this.setSelectedPackage({ ...packageItem, name: packageItem.name + ' - Modified' });
    this.packagesList$.pipe(take(1)).subscribe(packages => {
      const selectedPackage = packages.find(item => item.id === packageItem.id);
      if (!selectedPackage.name.includes('Modified')) {
        selectedPackage.name = selectedPackage.name + ' - Modified';
      }
    });
  }

  updateStartDate(newStartDate: Date): void {
    let updatedData = this.serverData;
    updatedData.startDate = this.datePipe.transform(newStartDate, 'yyyy-MM-dd');

    if (this.serverData?.signatures?.length) {
      updatedData = this.appService.setEmptySignature(updatedData);
    }
    this.updateQuote(updatedData, null).subscribe();
  }

  unCompleteAllSteps() {
    this.stepperService.unCompleteAllSteps();
    this.selectedPackage$.next(null);
    this.packagesList$.next([]);
  }

  generateApplicationPayload(applicationData: ApplicationView): Observable<ApplicationViewPayload> {
    return new Observable(observer => {
      this.selectedPackage$.pipe(take(1)).subscribe(packageItem => {
        const selectedBenefitIds: string[] = this.appService.generateSelectedBenefitIds(packageItem);
        const applicationDataPayload = {
          ...applicationData,
          selectedPackageId: packageItem ? packageItem.id : null,
          selectedBenefitOptionIds: selectedBenefitIds,
          listBillId: this.isListBillMode ? this.customerService.getListBillId() : null,
        };
        observer.next(applicationDataPayload);
        observer.complete();
      });
    });
  }

  onPaymentSubmit(applicationData: ApplicationView) {
    this.quote$.next({ ...applicationData, isPaymentSubmitted: true });
  }

  onPaymentMethodChange(data: { method: PaymentMethodTypeEnum; quote: ApplicationView; selectedPackageId: string }) {
    this.setCurrentPaymentMethod(data.method);
    this.appRepository
      .getApplication(data.quote.id, this.currentPaymentMethod === PaymentMethodTypeEnum.CARD)
      .subscribe(res => {
        this.setCurrentAppData(res);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
