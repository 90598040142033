<mat-expansion-panel
  [disabled]="!stepState.isEnabled"
  hideToggle="true"
  [(expanded)]="stepState.isOpened"
  (closed)="updateUnsavedData()"
  (opened)="resetUnsavedData()">
  <mat-expansion-panel-header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <mat-panel-title>
          <span>Step 4</span>
          <span class="dot-divider"></span>
          <span>{{ stepState.stepName }}</span>
        </mat-panel-title>
        @if (stepState.isCompleted) {
          <div class="complete-icon">
            <mat-icon>check</mat-icon>
          </div>
        }
      </div>
      @if (stepState.isOpened && !stepState.isCompleted) {
        <div class="step-description">Enter the details:</div>
      }
      @if (stepState.isCompleted) {
        <div class="d-flex align-items-center pt-1 step-completeness-info">
          <mat-icon class="me-2">assignment_ind</mat-icon>
          <div>
            <div>
              <span>1 Package for {{ customersFormArray?.controls?.length }} participants</span>
            </div>
          </div>
        </div>
      }
    </div>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="wrap-enrollment-form pt-3 pb-4">
      <div class="d-flex flex-column align-items-start mb-2 enrollment-form" [formGroup]="enrollmentForm">
        <div class="d-flex flex-column mb-4 enrollment-subtitle">
          <span class="package-title mb-2">{{ selectedPackageData?.name }}</span>
          <span class="package-sub-title mb-2">{{ selectedPackageData?.description }}</span>
          @if (stepState.isOpened) {
            <app-plan-start-date-section
              [control]="enrollmentForm.get('startDate')"
              [isReadOnly]="serverData?.isPaymentSubmitted || isLockedMode"
              [isUpdateImmediately]="!!serverData?.signatures.length"
              (updateStartDateEvent)="updateStartDateWithResetSign()">
            </app-plan-start-date-section>
          }
        </div>

        <div class="d-flex flex-column align-items-start mb-4 customers-controls-group">
          @for (customer of customersFormArray?.controls; track customer; let index = $index) {
            <app-customer-item
              type="fullData"
              [customer]="customer"
              [isReadOnly]="serverData?.isPaymentSubmitted || isLockedMode"
              [childIndex]="getChildIndex(customer)"
              (holderNameChangeEvent)="onHolderNameChange()">
            </app-customer-item>
          }
        </div>

        <div class="d-flex flex-column align-items-start contact-info-container">
          <div class="section-title">
            <span>Contact Information</span>
          </div>

          <div class="d-flex contact-info">
            <mat-form-field class="mb-2 phone-field" appearance="outline">
              <mat-label>Phone number</mat-label>
              <input type="text" matInput formControlName="phone" class="phone-input" />
              <mat-icon class="field-icon">phone_android</mat-icon>

              @if (enrollmentForm.controls.phone.errors?.required) {
                <mat-error>Phone Number is required.</mat-error>
              }
              @if (enrollmentForm.controls.phone.errors?.pattern) {
                <mat-error>Invalid Phone Number format.</mat-error>
              }
            </mat-form-field>

            <mat-form-field class="mb-2 email-field" appearance="outline">
              <mat-label>Email</mat-label>
              <input type="text" matInput formControlName="email" class="email-input" />

              @if (enrollmentForm.controls.email.errors?.required) {
                <mat-error>Email is required.</mat-error>
              }
              @if (enrollmentForm.controls.email.errors?.pattern) {
                <mat-error>Email format does not match.</mat-error>
              }
            </mat-form-field>
          </div>

          <div class="mb-4">
            <mat-checkbox formControlName="isAddressTheSame" (change)="onMailingAddressChange($event)"></mat-checkbox>
            <span>Billing & Mailing Address is the same</span>
          </div>

          <div class="w-100 d-flex mb-4 address-container">
            <div class="address-section">
              <div class="text-bolder section-title"><span>Billing Address</span></div>
              <mat-form-field class="me-4 street-field" appearance="outline">
                <mat-label>Street Address</mat-label>
                <input type="text" matInput appControlTrimFormat formControlName="streetAddress" />
                @if (enrollmentForm.controls.streetAddress.errors?.required) {
                  <mat-error>Street Address is required.</mat-error>
                }
              </mat-form-field>

              <mat-form-field class="mb-2 city-field" appearance="outline">
                <mat-label>City</mat-label>
                <input type="text" matInput appControlTrimFormat formControlName="city" />
                @if (enrollmentForm.controls.city.errors?.required) {
                  <mat-error>City is required.</mat-error>
                }
              </mat-form-field>

              <div class="d-flex contact-info">
                <mat-form-field class="mb-2 location-field" appearance="outline">
                  <mat-label>ZIP Code</mat-label>
                  <input type="text" matInput formControlName="zipCode" class="location-input" />
                  <mat-icon class="field-icon">my_location</mat-icon>
                </mat-form-field>

                <mat-form-field class="mb-2 state-field" appearance="outline">
                  <mat-label>State</mat-label>
                  <input type="text" matInput formControlName="state" />
                </mat-form-field>
              </div>
            </div>

            @if (!enrollmentForm.get('isAddressTheSame').value) {
              <div class="address-section">
                <div class="text-bolder section-title"><span>Mailing Address</span></div>
                <mat-form-field class="me-4 street-field" appearance="outline">
                  <mat-label>Street Address</mat-label>
                  <input type="text" matInput appControlTrimFormat formControlName="mailingStreetAddress" />
                  @if (enrollmentForm.controls.mailingStreetAddress.errors?.required) {
                    <mat-error>Street Address is required.</mat-error>
                  }
                </mat-form-field>

                <mat-form-field class="mb-2 city-field" appearance="outline">
                  <mat-label>City</mat-label>
                  <input type="text" matInput appControlTrimFormat formControlName="mailingCity" />
                  @if (enrollmentForm.controls.mailingCity.errors?.required) {
                    <mat-error>City is required.</mat-error>
                  }
                </mat-form-field>

                <div class="d-flex contact-info">
                  <mat-form-field class="mb-2 location-field" appearance="outline">
                    <mat-label>ZIP Code</mat-label>
                    <input
                      type="text"
                      matInput
                      appControlTrimFormat
                      formControlName="mailingZipCode"
                      class="location-input" />
                    <mat-icon class="field-icon">my_location</mat-icon>
                    @if (enrollmentForm.controls.mailingZipCode.errors?.required) {
                      <mat-error>ZIP Code is required.</mat-error>
                    }
                    @if (enrollmentForm.controls.mailingZipCode.errors?.zipCodeError) {
                      <mat-error>Invalid ZIP Code.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="mb-2 state-field" appearance="outline">
                    <mat-label>State</mat-label>
                    <input type="text" matInput formControlName="mailingState" [value]="currentMailingState" />
                  </mat-form-field>
                </div>
              </div>
            }
          </div>
        </div>

        <div class="w-100 d-flex flex-column mb-5">
          @if (isAgentMode) {
            <button
              id="shareQuoteByEmailBtn"
              mat-stroked-button
              color="primary"
              class="d-flex align-items-center share-app-btn mb-3"
              [disabled]="enrollmentForm.invalid"
              (click)="shareAppToClientClick('email')"
              type="button">
              <mat-icon>share</mat-icon>
              Share Application with Client by email
            </button>
            <button
              id="shareQuoteBySMSBtn"
              mat-stroked-button
              color="primary"
              class="d-flex align-items-center share-app-btn"
              [disabled]="enrollmentForm.invalid"
              (click)="shareAppBySmsClick()"
              type="button">
              <mat-icon>share</mat-icon>
              Share Application with Client by SMS
            </button>
          }
        </div>

        <div [formGroup]="signAgreementForm">
          @if (isAgentMode) {
            <div class="agent-confirm-block mb-5">
              <mat-radio-group aria-label="Select an option" formControlName="agentSignatureType">
                <mat-radio-button [value]="signatureType.BY_AGENT_ON_APPLICANT_BEHALF">
                  I attest that the primary applicant is present and signing on their own behalf.
                </mat-radio-button>
                <mat-radio-button [value]="signatureType.BY_AGENT_ON_VERBAL_SIGNATURE">
                  I attest the primary applicant’s verbal signature is being recorded and such recording will be made
                  available to the insurer upon request. Recordings must be stored for 4 years.
                </mat-radio-button>
              </mat-radio-group>
            </div>
          }

          @if (!serverData?.signatures.length) {
            <div class="d-flex sign-agent">
              <div class="w-100 d-flex sign-agent-content">
                <div class="w-50 d-flex flex-column sign-agent-entry-data">
                  @if (!isPackageWithoutCompanionCompany) {
                    <ng-container>
                      <div class="d-flex agreement-header">
                        <span class="sign-agent-title">Sign Companion Life Insurance Company Enrollment</span>

                        <button
                          id="previewAgreementBtn"
                          mat-stroked-button
                          color="primary"
                          class="btn-lg"
                          type="button"
                          [disabled]="enrollmentForm.invalid"
                          (click)="onPreviewCompleteAgreementClick(insuranceDocumentType.INSURANCE)">
                          <mat-icon>picture_as_pdf</mat-icon>
                          Preview
                        </button>
                      </div>

                      <div>
                        <mat-checkbox
                          formControlName="insuranceAcceptance"
                          (change)="onAcceptanceChange($event, insuranceDocumentType.INSURANCE)"></mat-checkbox>
                        <span>
                          I've read and accept
                          <span
                            class="app-link link-to-info"
                            (click)="openAcceptanceDialog(insuranceDocumentType.INSURANCE)">
                            Acceptance and Acknowledgement
                          </span>
                          terms
                        </span>
                      </div>

                      <mat-form-field class="mb-2" appearance="outline">
                        <mat-label>Your name</mat-label>
                        <input type="text" matInput formControlName="insuranceSignature" />
                        @if (signAgreementForm.controls.insuranceSignature.errors?.required) {
                          <mat-error>Your Name is required.</mat-error>
                        }

                        @if (signAgreementForm.controls.insuranceSignature.errors?.signatureError) {
                          <mat-error>Your Name should include your first or/and last name.</mat-error>
                        }
                      </mat-form-field>
                    </ng-container>
                  }

                  <ng-container>
                    <div class="d-flex agreement-header">
                      <span class="sign-agent-title">Sign Communicating for America Enrollment</span>
                      <button
                        id="previewAgreementBtn"
                        mat-stroked-button
                        color="primary"
                        class="btn-lg"
                        type="button"
                        [disabled]="enrollmentForm.invalid"
                        (click)="onPreviewCompleteAgreementClick(insuranceDocumentType.COMMUNICATING_FOR_AMERICA)">
                        <mat-icon>picture_as_pdf</mat-icon>
                        Preview
                      </button>
                    </div>
                    <div>
                      <mat-checkbox
                        formControlName="communicatingAmericaAcceptance"
                        (change)="
                          onAcceptanceChange($event, insuranceDocumentType.COMMUNICATING_FOR_AMERICA)
                        "></mat-checkbox>
                      <span>
                        I've read and accept
                        <span
                          class="app-link link-to-info"
                          (click)="openAcceptanceDialog(insuranceDocumentType.COMMUNICATING_FOR_AMERICA)">
                          Acceptance and Acknowledgement
                        </span>
                        terms
                      </span>
                    </div>

                    <mat-form-field class="mb-2" appearance="outline">
                      <mat-label>Your name</mat-label>
                      <input type="text" matInput formControlName="communicatingAmericaSignature" />
                      @if (signAgreementForm.controls.communicatingAmericaSignature.errors?.required) {
                        <mat-error>Your Name is required.</mat-error>
                      }

                      @if (signAgreementForm.controls.communicatingAmericaSignature.errors?.signatureError) {
                        <mat-error>Your Name should include your first or/and last name.</mat-error>
                      }
                    </mat-form-field>
                  </ng-container>
                </div>
                <div class="w-50 pt-4 sign-agent-description">
                  <p>
                    If this Enrollment Form is completed electronically, I agree that my electronic signature serves as
                    my original signature. If this Enrollment Form is not completed electronically, I agree to provide
                    my recorded verbal consent to certify my Enrollment Form in lieu of a signature. I have read this
                    Enrollment Form and have verified that all the information provided in it is complete, true and
                    correct, and is all within my personal knowledge.
                  </p>
                  @if (enrollmentForm.controls.state.value === 'MN') {
                    <p>
                      Authorization for Release of Medical Records: I authorize any third party to share information
                      regarding myself and any dependents. This includes any medical or non‐medical information and
                      to share any and all such information with Companion Life Insurance Company, its reinsurer or
                      its legal representatives, and its affiliates. Once personal and health (including medical,
                      dental and pharmacy) information is disclosed pursuant to this authorization, the recipient may
                      redisclose it and the information may not be protected by federal and state privacy requirements.
                      If I do not revoke this authorization, it will be valid, for as long as I am continually insured
                      with Companion Life Insurance Company. I understand that I have the right to revoke this
                      Authorization in writing, at any time, by sending a written request for revocation to the Company.
                    </p>
                  }
                </div>
              </div>

              <button
                id="signAgreementBtn"
                mat-raised-button
                color="primary"
                class="btn-lg sign-btn"
                type="button"
                [disabled]="enrollmentForm.invalid || signAgreementForm.invalid"
                (click)="onSignAgreementClick()">
                Sign Agreement
              </button>
            </div>
          } @else {
            @if (!isPackageWithoutCompanionCompany) {
              <div class="d-flex flex-column signed-section">
                <span class="sign-agent-title">Companion Life Insurance Company</span>
                <span class="sign-agent-title">enrollment is signed by</span>
                <span class="signature">{{ insuranceSignature?.signature }}</span>
                <div class="d-flex signed-section-info">
                  <span>IP {{ insuranceSignature?.ip }}</span>
                  <span>{{ insuranceSignature?.signTimeStamp | date: 'MM/dd/yyyy hh:mm:ss' }}</span>
                </div>
              </div>
            }

            <div class="d-flex flex-column signed-section">
              <span class="sign-agent-title">Communicating for America</span>
              <span class="sign-agent-title">enrollment is signed by</span>
              <span class="signature">{{ communicatingAmericaSignature?.signature }}</span>
              <div class="d-flex signed-section-info">
                <span>IP {{ communicatingAmericaSignature?.ip }}</span>
                <span>{{ communicatingAmericaSignature?.signTimeStamp | date: 'MM/dd/yyyy hh:mm:ss' }}</span>
              </div>
            </div>
          }
        </div>
        @if (!serverData?.isPaymentSubmitted) {
          <button
            id="signAndContinueBtn"
            mat-raised-button
            color="primary"
            class="btn-lg next-btn"
            type="button"
            [disabled]="enrollmentForm.invalid || !serverData?.signatures.length"
            (click)="goToPurchaseStep()">
            Continue
          </button>
        }
      </div>
    </div>
  </div>
</mat-expansion-panel>

<ng-template #acceptanceDialog>
  <app-modal-dialog>
    <div [innerHTML]="acceptanceRules | safeHTML"></div>
  </app-modal-dialog>
</ng-template>

<ng-template #changeDataDialog>
  <app-modal-dialog id="changeDataDialog">
    <div class="mb-3">Information on this screen is the basic one for creation Quote prices.</div>
  </app-modal-dialog>
</ng-template>

<ng-template #shareAppBySmsDialog>
  <app-modal-dialog>
    <div class="mb-3">Client has provided permission to receive the application via SMS message.</div>
  </app-modal-dialog>
</ng-template>
