import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getStateByZipCode } from './state-by-zipcode';
import { regexPatterns } from './regex';

export const zipCodeValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null;
    }

    if (!regexPatterns.onlyNumbers.test(value)) {
      return { zipCodeError: true };
    }

    const currentState = getStateByZipCode(+value);

    return currentState === 'none' ? { zipCodeError: true } : null;
  };
};

export const zipCodeValueValidation = (value: string): boolean => {
  const currentValue = value.trim();

  if (!currentValue) {
    return false;
  }

  if (!regexPatterns.onlyNumbers.test(currentValue)) {
    return false;
  }

  const currentState = getStateByZipCode(+currentValue);

  return currentState === 'none' ? false : true;
};
