<div class="shared-package-page">
  <div class="container">
    @if (package) {
      <div class="d-flex justify-content-end mb-5">
        <button
          id="printPackageBtn"
          mat-stroked-button
          color="primary"
          class="d-flex align-items-center print-package-btn"
          (click)="printPackage()"
          type="button">
          <mat-icon>print</mat-icon>
          Print Package
        </button>
      </div>
      <div class="d-flex mb-5 flex-wrap">
        <div class="package-title-container pe-3">
          <div class="package-title mb-2">{{ package?.name }}</div>
          <div class="package-sub-title mb-2">{{ package?.description }}</div>
          <div class="text-print-default">
            Plan starts on
            <span class="text-bolder">{{ quote?.startDate }}</span>
            for
            <span class="text-bolder">{{ quote?.customers?.length }} people in {{ currentState }}.</span>
          </div>
        </div>
        <div class="price-summary-container">
          <app-package-price-summary [selectedPackage]="package"></app-package-price-summary>
        </div>
      </div>
      <app-package-content [selectedPackage]="package" [isReadOnly]="true"></app-package-content>
      <div class="d-flex justify-content-end mt-5">
        <button
          id="printPackageBottomBtn"
          mat-stroked-button
          color="primary"
          class="d-flex align-items-center print-package-btn"
          (click)="printPackage()"
          type="button">
          <mat-icon>print</mat-icon>
          Print Package
        </button>
      </div>
    }
  </div>
</div>

<iframe class="print-iframe" #printIframe></iframe>

<div class="d-none">
  <ng-template #printContent>
    <div class="print-content">
      <div class="benefits-page-content">
        <div class="d-flex mb-5">
          <div class="w-50 pe-3">
            <div class="package-title mb-2">{{ package?.name }}</div>
            <div class="package-sub-title mb-2">{{ package?.description }}</div>
            <div class="text-print-default">
              Plan starts on
              <span class="text-bolder">{{ quote?.startDate }}</span>
              for
              <span class="text-bolder">{{ quote?.customers?.length }} people in {{ currentState }}.</span>
            </div>
          </div>
          <div class="w-50">
            <app-package-price-summary [selectedPackage]="package"></app-package-price-summary>
          </div>
        </div>
        <div>
          <app-package-content [selectedPackage]="package" [isReadOnly]="true"></app-package-content>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="print-container">
  <ng-container *ngTemplateOutlet="printContent"></ng-container>
</div>
