import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-heart-icon-toggle',
  standalone: true,
  imports: [],
  templateUrl: './heart-icon-toggle.component.html',
  styleUrl: './heart-icon-toggle.component.scss',
})
export class HeartIconToggleComponent implements OnInit{
  @Input() isActive: boolean = false;
  primaryColor: string = '';

  constructor(private customerService: CustomerService) {}

  ngOnInit() {
    this.primaryColor = this.customerService.getAppPrimaryColor();
  }
}
