import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const startDateRangeValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const value = new Date(control.value);
    const today = new Date(new Date().getTime());
    const limitDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 63);
    const condition = value > today && value <= limitDay;

    return !condition ? { startDateRangeError: true } : null;
  };
};

export const dependentAgeRestrictionValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(control.value);
    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    return yearDiff > 26 || (yearDiff === 26 && monthDiff > 0) || (yearDiff === 26 && monthDiff === 0 && dayDiff > 0)
      ? { ageExceeded: true }
      : null;
  };
};

export const insuranceHolderAgeRestrictionValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(control.value);
    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (yearDiff < 18 || (yearDiff === 18 && monthDiff < 0) || (yearDiff === 18 && monthDiff === 0 && dayDiff < 0))
      return { ageLow: true };

    if (yearDiff > 72 || (yearDiff === 72 && monthDiff > 0) || (yearDiff === 72 && monthDiff === 0 && dayDiff > 0))
      return { ageHigh: true };

    return null;
  };
};

export const insuranceSpouseAgeRestrictionValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(control.value);
    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (yearDiff > 72 || (yearDiff === 72 && monthDiff > 0) || (yearDiff === 72 && monthDiff === 0 && dayDiff > 0))
      return { ageHigh: true };

    return null;
  };
};

export const routingNumberValidation = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (control.value.length !== 9) {
      return { invalidRoutingNumber: true };
    } else {
      let sum = 0;
      for (let i = 0; i < 9; i += 3) {
        sum += parseInt(control.value[i].toString()) * 3;
        sum += parseInt(control.value[i + 1].toString()) * 7;
        sum += parseInt(control.value[i + 2].toString());
      }
      const result = sum != 0 && sum % 10 == 0;
      return result ? null : { invalidRoutingNumber: true };
    }
  };
};
