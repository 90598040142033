import { Injectable } from '@angular/core';
import { IAgent, IBill } from '../models/common.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private agentId: string = '00000000-0000-0000-0000-000000000000';
  private agent: IAgent = null;
  private listBill: IBill = null;
  private applicationId: string;
  private listBillId: string;
  private isAgentMode: boolean = false;
  private islLockedMode: boolean = false;
  private isListBillMode: boolean = false;
  agentSubject$ = new BehaviorSubject(this.agent);
  listBillSubject$ = new BehaviorSubject(this.listBill);
  constructor() {}

  setAppId(id: string) {
    this.applicationId = id;
  }

  getAppId() {
    return this.applicationId;
  }

  setListBillId(id: string) {
    this.listBillId = id;
  }

  getListBillId() {
    return this.listBillId;
  }

  getAgent() {
    return this.agent;
  }

  getAgentId() {
    return this.agentId;
  }

  setAgentId(id: string) {
    this.agentId = id;
  }

  setAgentMode(isAgentMode: boolean) {
    this.isAgentMode = isAgentMode;
  }

  setLockedMode(islLocked: boolean) {
    this.islLockedMode = islLocked;
  }

  isAgentModeActive() {
    return this.isAgentMode;
  }

  setListBillMode(isListBillMode: boolean) {
    this.isListBillMode = isListBillMode;
  }

  isListBillModeActive() {
    return this.isListBillMode;
  }

  isLockedModeActive() {
    return this.islLockedMode;
  }

  setAgent(agentData: IAgent) {
    this.agent = agentData;
    this.setAgentId(this.agent.id);
    this.agentSubject$.next(this.agent);
  }

  setListBill(billData: IBill) {
    this.listBill = billData;
    this.setListBillId(this.listBill.id);
    this.listBillSubject$.next(this.listBill);
  }
}
