@if (isEnvSettingsReady && isProd) {
  <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQKW5MTN" height="0" width="0" style="display:none; visibility:hidden"></iframe>
  </noscript>
}
<div class="page-content">
  <app-header></app-header>
  <div class="content-body" id="contentBody">
    @if (isEnvSettingsReady) {
      <router-outlet></router-outlet>
    }
  </div>
  <app-footer></app-footer>
  <app-loader></app-loader>
</div>
