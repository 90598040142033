<div class="h-100 w-100 d-flex flex-column">
  <div class="container flex-grow-1 mb-3 pb-3 mb-md-5 pb-md-5">
    <h1 class="page-title">Flexible Solutions that Fit You</h1>
    <h2 class="page-description">Because Life is an Unpredictable Journey</h2>
  </div>
  <div id="stepperContainer">
    <mat-accordion>
      <app-quote-step
        [quote]="quote$ | async"
        [isQuoteSaved]="isQuoteSaved"
        (saveQuoteEvent)="saveFirstStepData($event)"
        (unCompleteAllStepsEvent)="unCompleteAllSteps()"
        (saveQuoteWithSendLinkEvent)="saveQuoteWithSendLink($event, false)"
        (goToPackagesStepEvent)="goToPackagesStep($event)">
      </app-quote-step>
      <app-insurance-plan-step
        [quote]="quote$ | async"
        [packagesList]="packagesList$ | async"
        [selectedPackage]="selectedPackage$ | async"
        (selectPackageEvent)="onSelectPackage($event)">
      </app-insurance-plan-step>
      <app-benefits-step
        [quote]="quote$ | async"
        [isQuoteSaved]="isQuoteSaved"
        [selectedPackage]="selectedPackage$ | async"
        [insuranceStartDate]="(quote$ | async)?.startDate"
        [currentPaymentMethod]="currentPaymentMethod"
        (updateStartDateEvent)="updateStartDate($event)"
        (benefitLevelChangeEvent)="onBenefitLevelChange($event)"
        (goToEnrollmentStepEvent)="goToEnrollmentStep($event)">
      </app-benefits-step>
      <app-enrollment-step
        [quote]="quote$ | async"
        [isQuoteSaved]="isQuoteSaved"
        [selectedPackage]="selectedPackage$ | async"
        (saveQuoteWithSendLinkEvent)="saveQuoteWithSendLink($event, true)"
        (signAgreementEvent)="signAgreementQuote($event)">
      </app-enrollment-step>
      <app-purchase-step
        [quote]="quote$ | async"
        [selectedPackage]="selectedPackage$ | async"
        [insuranceStartDate]="(quote$ | async)?.startDate"
        [currentPaymentMethod]="currentPaymentMethod"
        (paymentSubmitEvent)="onPaymentSubmit($event)"
        (paymentMethodChangeEvent)="onPaymentMethodChange($event)"
        (changeStartDateEvent)="updateStartDate($event)">
      </app-purchase-step>
    </mat-accordion>
  </div>
</div>

@if (!(quote$ | async)?.isPaymentSubmitted) {
  <app-plan-start-date-section
    *ngIf="startDateControl.value"
    [isOpenByDefault]="startDateControl.invalid"
    [isStartDateExpired]="true"
    [control]="startDateControl"
    (updateStartDateEvent)="updateStartDate($event)">
  </app-plan-start-date-section>
}
