<div id="{{ data.id }}" class="dialog-container" [class]="data?.dialogContainerClass || ''">
  <div class="dialog-header">
    @if (!data.hideCloseBtn) {
      <button class="close-icon btn-sm" (click)="onCancelClick()" mat-icon-button aria-label="Close modal dialog">
        <mat-icon>close</mat-icon>
      </button>
    }
    @if (data.title) {
      <div mat-dialog-title class="dialog-title">
        {{ data.title }}
      </div>
    }
    @if (data.description) {
      <div class="dialog-description mb-4">{{ data.description }}</div>
    }
  </div>
  @if (data.hasContent) {
    <div mat-dialog-content class="dialog-content">
      <ng-content></ng-content>
    </div>
  }
  @if (!data.hideActions) {
    <div class="dialog-footer">
      <div mat-dialog-actions class="dialog-actions d-flex align-items-center justify-content-between p-0">
        @if (data.showConfirmBtn) {
          <button mat-raised-button class="btn-sm" color="primary" (click)="onConfirmClick()">
            {{ data.confirmActionText ? data.confirmActionText : 'OK' }}
          </button>
        }
        @if (data.showCancelBtn) {
          <button mat-button color="primary" class="btn-sm" (click)="onCancelClick()">
            {{ data.cancelActionText ? data.cancelActionText : 'CANCEL' }}
          </button>
        }
      </div>
    </div>
  }
</div>
