import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicationView, IPackage, MappedQuoting, MappedQuotingView } from '../models/application.interface';
import { CustomerTypeEnum } from '../enums/customerType.enum';
import { isEqual, reduce } from 'lodash-es';
import { getTimeZoneOffsetMinutes } from '../utils/date-format';
import { regexPatterns } from '../utils/regex';
import { zipCodeValueValidation } from '../utils/zip-code-validation';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  saveQuoteWithSendLinkSubject$: BehaviorSubject<{ email: string; startDate: string }> = new BehaviorSubject<{
    email: string;
    startDate: string;
  }>(null);

  private unsavedEnrolmentFormValue: ApplicationView | null = null;

  constructor() {}

  generatePackageTotalPrice(packageItem: IPackage, isListBillMode: boolean) {
    let totalPrice = 0;
    packageItem.pricePerPeriod = 0;
    packageItem.companies.forEach(company => {
      company.products.forEach(product => {
        totalPrice += product.price;
        if (isListBillMode) {
          packageItem.pricePerPeriod += product.pricePerPeriod;
        }
      });
    });
    if (isListBillMode) {
      packageItem.pricePerPeriod = +packageItem.pricePerPeriod.toFixed(2);
    }
    totalPrice = +totalPrice.toFixed(2);
    return totalPrice;
  }

  generateSelectedBenefitIds(packageItem: IPackage): string[] {
    const res: string[] = [];
    if (packageItem) {
      packageItem.companies.forEach(company => {
        company.products.forEach(product => {
          product.benefits.forEach(benefit => {
            benefit.levels.forEach(level => {
              if (level.isSelected) {
                res.push(level.id);
              }
            });
          });
        });
      });
    }
    return res;
  }

  getMappedQuoting(res: ApplicationView, isPolicyHolderIncluded: boolean): MappedQuotingView {
    const policyHolder = res.customers.find(customer => customer.customerType === CustomerTypeEnum.FORM_SUBMITTER);
    return {
      id: res.id,
      agentId: res.agentId,
      email: res.contactInfo.email,
      zipCode: res.contactInfo.zipCode,
      city: res.contactInfo.city,
      phone: res.contactInfo.phone,
      streetAddress: res.contactInfo.streetAddress,
      mailingStreetAddress: res.contactInfo.mailingStreetAddress,
      mailingCity: res.contactInfo.mailingCity,
      mailingZipCode: res.contactInfo.mailingZipCode,
      startDate: res.startDate,
      dateOfBirth: policyHolder.dateOfBirth,
      genderType: policyHolder.genderType,
      submissionDate: res.submissionDate,
      signatures: res.signatures,
      customers: isPolicyHolderIncluded
        ? res.customers
        : res.customers.filter(customer => customer.customerType !== CustomerTypeEnum.FORM_SUBMITTER),
    };
  }

  setEmptySignature(quoteData: ApplicationView): ApplicationView {
    return {
      ...quoteData,
      signatures: [],
      updateSignature: true,
      timeZoneOffsetMinutes: getTimeZoneOffsetMinutes(),
    };
  }

  getChangedFieldName(
    prevFormValue: MappedQuotingView | MappedQuoting,
    changedFormValue: MappedQuotingView | MappedQuoting
  ) {
    return reduce(
      prevFormValue,
      (result, value, key) => {
        return isEqual(value, changedFormValue[key]) ? result : result.concat(key);
      },
      []
    ).toString();
  }

  setUnsavedData(value: ApplicationView | null): void {
    this.unsavedEnrolmentFormValue = value;
  }

  getUnsavedData(): ApplicationView {
    return this.unsavedEnrolmentFormValue;
  }

  combineUnsavedData(res: ApplicationView): ApplicationView {
    const unsavedData = this.unsavedEnrolmentFormValue;
    const updatedApplicationData: ApplicationView = {
      ...res,
      contactInfo: {
        ...res.contactInfo,
        phone: unsavedData.contactInfo.phone,
        email: res.contactInfo.email ? res.contactInfo.email : unsavedData.contactInfo.email,
        city: unsavedData.contactInfo.city,
        streetAddress: unsavedData.contactInfo.streetAddress,
        mailingCity: unsavedData.contactInfo.mailingCity,
        mailingStreetAddress: unsavedData.contactInfo.mailingStreetAddress,
        mailingZipCode: unsavedData.contactInfo.mailingZipCode,
      },
      customers: res.customers.map(customer => {
        const item = unsavedData.customers.find(unsavedCustomer => customer.sequence === unsavedCustomer.sequence - 1);
        customer.firstName = item ? item.firstName : '';
        customer.lastName = item ? item.lastName : '';
        return customer;
      }),
    };

    return updatedApplicationData;
  }

  combineValidUnsavedData(res: ApplicationView): ApplicationView {
    const unsavedData = this.unsavedEnrolmentFormValue;
    const unsavedEmail = regexPatterns.email.test(unsavedData.contactInfo.email) ? unsavedData.contactInfo.email : '';
    const unsavedPhone = regexPatterns.phone.test(unsavedData.contactInfo.phone) ? unsavedData.contactInfo.phone : null;
    const unsavedMailingZipCode = zipCodeValueValidation(unsavedData.contactInfo.mailingZipCode)
      ? unsavedData.contactInfo.mailingZipCode
      : null;
    const updatedApplicationData: ApplicationView = {
      ...res,
      contactInfo: {
        ...res.contactInfo,
        phone: unsavedPhone,
        email: res.contactInfo.email ? res.contactInfo.email : unsavedEmail,
        city: unsavedData.contactInfo.city,
        streetAddress: unsavedData.contactInfo.streetAddress,
        mailingCity: unsavedData.contactInfo.mailingCity,
        mailingStreetAddress: unsavedData.contactInfo.mailingStreetAddress,
        mailingZipCode: unsavedMailingZipCode,
      },
      customers: res.customers.map(customer => {
        const item = unsavedData.customers.find(unsavedCustomer => customer.sequence === unsavedCustomer.sequence - 1);
        customer.firstName = item ? item.firstName : '';
        customer.lastName = item ? item.lastName : '';
        return customer;
      }),
    };

    return updatedApplicationData;
  }

  getFirstDayOfNextMonth() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    return new Date(nextYear, nextMonth, 1);
  }
}
