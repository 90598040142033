import { Component, OnInit } from '@angular/core';
import { IAgent } from '../../models/common.interface';
import { CustomerService } from '../../services/customer.service';
import { MatIcon } from '@angular/material/icon';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-print-page-header',
  standalone: true,
  imports: [MatIcon, QRCodeModule],
  templateUrl: './print-page-header.component.html',
  styleUrl: './print-page-header.component.scss',
})
export class PrintPageHeaderComponent implements OnInit {
  agent: IAgent;
  appUrl: string;
  constructor(private customerService: CustomerService) {}

  ngOnInit() {
    this.agent = this.customerService.getAgent();
    this.appUrl = location.href;
  }
}
