import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { ModalDialogData } from '../../models/modal-dialog.interface';
import { CustomerService } from '../../services/customer.service';
import { AppService } from '../../services/app.service';
import { DateInputFormatDirective } from '../../directives/date-input-format.directive';

@Component({
  selector: 'app-plan-start-date-section',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatDatepickerModule,
    MatDialogClose,
    ModalDialogComponent,
    DateInputFormatDirective,
  ],
  templateUrl: './plan-start-date-section.component.html',
  styleUrl: './plan-start-date-section.component.scss',
  providers: [provideNativeDateAdapter(), DatePipe],
})
export class PlanStartDateSectionComponent implements AfterViewInit {
  @Input() control: FormControl;
  @Input() isOpenByDefault: boolean;
  @Input() isStartDateExpired: boolean;
  @Input() isUpdateImmediately: boolean;
  @Input() isReadOnly: boolean;
  @Output() updateStartDateEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @ViewChild('startDateDialog') startDateDialog: TemplateRef<any>;
  startDateDialogRef: MatDialogRef<ModalDialogComponent>;
  rangeFilter: boolean = this.getRangeFilter.bind(this);
  billFilter = this.getBillFilter.bind(this);
  inputValue: string;
  invalidInputDate: boolean = false;
  isListBillMode: boolean;

  constructor(
    private dialog: MatDialog,
    private customerService: CustomerService,
    private appService: AppService,
  ) {}

  ngAfterViewInit(): void {
    this.isListBillMode = this.customerService.isListBillModeActive();
    if (this.isOpenByDefault) {
      this.invalidInputDate = true;

      setTimeout(() => {
        this.control.setValue(this.isListBillMode ? this.appService.getFirstDayOfNextMonth() : this.getTomorrowDate());
      }, 0);

      this.openInsuranceStartDateDialog();
    }
  }

  openInsuranceStartDateDialog(): void {
    this.inputValue = this.control.value;

    const modalDialogData: ModalDialogData = {
      adaptivePosition: true,
      title: 'Update Insurance Start Date',
      dialogContainerClass: 'insurance-modal-dialog',
      hideCloseBtn: this.invalidInputDate,
      hasContent: true,
      hideActions: true,
    };

    this.startDateDialogRef = this.dialog.open(this.startDateDialog, {
      data: modalDialogData,
      width: '420px',
      autoFocus: false,
      disableClose: true,
    });

    this.startDateDialogRef.afterClosed().subscribe(confirm => {
      this.invalidInputDate = false;

      if (!confirm) {
        this.control.setValue(this.inputValue, { emitEvent: false });
      }

      if (confirm && (this.isStartDateExpired || this.isUpdateImmediately)) {
        this.updateStartDateEvent.emit(this.control.value);
      }
    });
  }

  closeInsuranceStartDateDialog(): void {
    this.dialog.closeAll();
  }

  getRangeFilter(d: Date | null): boolean {
    const day = d || new Date();
    const today = new Date(new Date().getTime());
    const limitDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 63);

    return day > today && day <= limitDay;
  }

  getBillFilter(d: Date | null): boolean {
    if (!d) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isFirstDayOfMonth = d.getDate() === 1;
    const isFutureMonth = d > today && d.getDate() === 1;
    return isFirstDayOfMonth && isFutureMonth;
  }

  getTomorrowDate(): Date {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }
}
