import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor() {}

  attachStyles(targetWindow: Window): void {
    // Copy styles from parent window
    document.querySelectorAll('style').forEach(htmlElement => {
      targetWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });
    // Copy stylesheet link from parent window
    const styleSheetElement = this.generateStylesLink();
    const bootstrapLink = this.generateBootstrapStylesLink();
    targetWindow.document.head.appendChild(bootstrapLink);
    targetWindow.document.head.appendChild(styleSheetElement);
  }

  print(iframe) {
    const interval = setInterval(() => {
      const allImages = iframe.contentDocument.body.querySelectorAll('img');
      const loaded = Array.from({ length: allImages.length }).fill(false);
      allImages.forEach((img: HTMLImageElement, idx: number) => {
        loaded[idx] = img.complete && img.naturalHeight !== 0;
      });
      if (loaded.every(c => c === true)) {
        clearInterval(interval);
        iframe.contentWindow.print();
      }
    }, 500);
  }

  private generateStylesLink() {
    const styleSheetElement = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.type = 'text/css';
        styleSheetElement.href = absoluteUrl;
      }
    });
    return styleSheetElement;
  }

  private generateBootstrapStylesLink() {
    const styleSheetElement = document.createElement('link');
    styleSheetElement.rel = 'stylesheet';
    styleSheetElement.type = 'text/css';
    styleSheetElement.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css';
    return styleSheetElement;
  }
}
