import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ModalDialogData } from '../../models/modal-dialog.interface';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-modal-dialog',
  standalone: true,
  imports: [MatIconButton, MatIcon, MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose],
  templateUrl: './modal-dialog.component.html',
  styleUrl: './modal-dialog.component.scss',
})
export class ModalDialogComponent implements OnInit {
  @HostBinding('class.adaptive-position') adaptivePosition: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDialogData
  ) {}

  ngOnInit(): void {
    if (this.data.adaptivePosition) {
      this.adaptivePosition = true;
    }
  }

  onConfirmClick() {
    if (this.data.onConfirmClick) {
      this.data.onConfirmClick();
    } else {
      this.dialogRef.close(true);
    }
  }

  onCancelClick(): void {
    if (this.data.onCancelClick) {
      this.data.onCancelClick();
    } else {
      this.dialogRef.close();
    }
  }
}
