import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GenderTypeEnum } from '../../enums/genderType.enum';
import { CustomerTypeEnum } from '../../enums/customerType.enum';
import { CustomerDataTypeEnum } from '../../enums/customerDataType.enum';
import { ControlTrimFormatDirective } from '../../directives/control-trim-format.directive';
import { DateInputFormatDirective } from '../../directives/date-input-format.directive';

@Component({
  selector: 'app-customer-item',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIcon,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    ControlTrimFormatDirective,
    DateInputFormatDirective,
  ],
  templateUrl: './customer-item.component.html',
  styleUrl: './customer-item.component.scss',
  providers: [provideNativeDateAdapter(), DatePipe],
})
export class CustomerItemComponent implements OnInit {
  @Input() type: 'entryData' | 'fullData';
  @Input() customer: FormGroup;
  @Input() index: number;
  @Input() childIndex: number;
  @Input() isReadOnly: boolean;
  @Output() removeControl: EventEmitter<number> = new EventEmitter<number>();
  @Output() holderNameChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  customerType = CustomerTypeEnum;
  genderType = GenderTypeEnum;
  currentType = CustomerDataTypeEnum;
  ageFilter;

  ngOnInit(): void {
    if (this.customer.get('customerType').value === 2) {
      this.ageFilter = this.getSpouseAgeFilter.bind(this);
    } else {
      this.ageFilter = this.getChildAgeFilter.bind(this);
    }
    if (this.type === this.currentType.FULL_DATA) {
      this.disableControls();
    }
  }

  disableControls(): void {
    this.customer.get('dateOfBirth').disable({ emitEvent: false });
    this.customer.get('genderType').disable({ emitEvent: false });
  }

  get currentCustomerType(): number {
    return this.customer.get('customerType').value;
  }

  get labelTitle(): string {
    if (this.type === this.currentType.FULL_DATA) {
      return this.getChildLabel();
    } else {
      return this.getCustomerLabel();
    }
  }

  getChildLabel(): string {
    return `Child ${this.childIndex + 1} Info`;
  }

  getCustomerLabel(): string {
    return this.customer.get('customerType').value === 2 ? 'Spouse' : `Child ${this.childIndex + 1} (of 10)`;
  }

  removeSelectedControl(index: number): void {
    this.removeControl.emit(index);
  }

  getChildAgeFilter(d: Date | null): boolean {
    const today = new Date();
    const birthDate = d;
    const yearDiff = today.getFullYear() - birthDate?.getFullYear();
    const monthDiff = today.getMonth() - birthDate?.getMonth();
    const dayDiff = today.getDate() - birthDate?.getDate();

    return !(
      yearDiff > 26 ||
      (yearDiff === 26 && monthDiff > 0) ||
      (yearDiff === 26 && monthDiff === 0 && dayDiff > 0) ||
      birthDate >= today
    );
  }

  getSpouseAgeFilter(d: Date | null): boolean {
    const today = new Date();
    const birthDate = d;
    const yearDiff = today.getFullYear() - birthDate?.getFullYear();
    const monthDiff = today.getMonth() - birthDate?.getMonth();
    const dayDiff = today.getDate() - birthDate?.getDate();

    return !(
      yearDiff > 72 ||
      (yearDiff === 72 && monthDiff > 0) ||
      (yearDiff === 72 && monthDiff === 0 && dayDiff > 0) ||
      birthDate >= today
    );
  }

  checkHolderNameChange() {
    if (this.customer.get('customerType').value === 1) {
      if (this.customer.get('firstName').value && this.customer.get('lastName').value) {
        this.holderNameChangeEvent.emit();
      }
    }
  }
}
