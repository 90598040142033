import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-expandable-text',
  standalone: true,
  imports: [NgClass],
  templateUrl: './expandable-text.component.html',
  styleUrl: './expandable-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableTextComponent implements AfterViewInit {
  @Input() text: string;
  @Input() textList: string[];
  @ViewChild('textDiv') textDiv: ElementRef;
  isExpanded: boolean = true;
  showExpandLink: boolean = true;
  constructor(private detector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const textHeight = this.textDiv.nativeElement.offsetHeight;
    if (textHeight <= 42) {
      this.showExpandLink = false;
    } else {
      this.showExpandLink = true;
      this.isExpanded = false;
    }
    this.detector.markForCheck();
  }
}
