<mat-expansion-panel [disabled]="!stepState.isEnabled" [(expanded)]="stepState.isOpened" hideToggle="true">
  <mat-expansion-panel-header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <mat-panel-title>
          <span>Step 2</span>
          <span class="dot-divider"></span>
          <span>{{ stepState.stepName }}</span>
        </mat-panel-title>
        @if (stepState.isCompleted && !stepState.isOpened) {
          <div class="complete-icon">
            <mat-icon>check</mat-icon>
          </div>
        }
        @if (stepState.isOpened && packagesListData?.length) {
          <a class="app-link d-flex align-items-center" href="javascript:void(0)" (click)="onPrintListClick($event)">
            <mat-icon class="me-1">local_printshop</mat-icon>
            <span class="print-btn-label">Print Packages List</span>
          </a>
        }
      </div>
      @if (stepState.isOpened && !stepState.isCompleted) {
        <div class="step-description">Select a product to review its details.</div>
      }
      @if (stepState.isCompleted) {
        <div class="d-flex align-items-center pt-1 step-completeness-info">
          <mat-icon class="me-2">inventory</mat-icon>
          <div>
            <div>{{ selectedPackageData?.name }}</div>
          </div>
        </div>
      }
    </div>
  </mat-expansion-panel-header>
  <div class="container pt-4 packages-list-container">
    @if (packagesListData.length) {
      <ng-container *ngTemplateOutlet="packagesList"></ng-container>
    } @else {
      <div class="empty-packages-list">
        <span>No products available.</span>
      </div>
    }
  </div>
</mat-expansion-panel>

<iframe class="print-iframe" #printIframe></iframe>

<div class="d-none">
  <ng-template #packagesList>
    <div class="packages-list">
      @if (packagesListData) {
        @for (packageItem of packagesListData; track packageItem.id) {
          <div class="package-card" [ngClass]="isListBillMode ? 'bill-mode' : ''">
            <div class="package-card-header">
              <div class="package-title mb-1">{{ packageItem.name }}</div>
              <div class="card-sub-title">{{ packageItem.description }}</div>
            </div>
            <div class="package-card-content">
              @for (company of packageItem.companies; track company.name) {
                <div class="mb-1">{{ company.name }}:</div>
                <div class="mb-4">
                  @for (product of company.products; track product.name) {
                    <div class="mb-3">
                      <div class="product-title">{{ product.name }}</div>
                      @for (benefit of product.benefits; track benefit.name) {
                        <div class="d-flex align-items-center justify-content-between">
                          <div>{{ benefit.name }}</div>
                          <div>{{ benefit.amount | priceFormat }}</div>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
            <div class="package-card-footer">
              <div class="card-sub-title text-center mb-3">{{ packageItem.price | priceFormat }}/mo.</div>
              @if (isListBillMode) {
                <div class="card-sub-title text-center mb-3">
                  {{ packageItem.pricePerPeriod | priceFormat }} per pay period
                </div>
              }
              <div class="card-actions">
                @if (selectedPackageData?.id === packageItem.id) {
                  <div class="selected-icon-container d-flex align-items-center justify-content-center pt-2">
                    <mat-icon class="text-primary-blue me-2">check</mat-icon>
                    <span class="label text-primary-blue">Selected</span>
                  </div>
                } @else {
                  @if (!quoteData?.isPaymentSubmitted && !isLockedMode) {
                    <button
                      id="selectProductBtn{{ packageItem.id }}"
                      mat-stroked-button
                      class="w-100 card-btn"
                      color="primary"
                      type="button"
                      (click)="onSelectPackageClick(packageItem)">
                      Select Product
                    </button>
                  }
                }
              </div>
            </div>
          </div>
        }
      }
    </div>
  </ng-template>
  <ng-template #printContent>
    <div class="print-content">
      <app-print-page-header></app-print-page-header>
      <div class="text-print-default mb-4">
        Here are your personalized insurance quotes that
        <span class="text-bolder">starts on {{ quoteData?.startDate }}</span>
        for
        <span class="text-bolder">{{ quoteData?.customers?.length }} people in {{ currentState }}.</span>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="packagesList"></ng-container>
      </div>
    </div>
  </ng-template>
</div>

<div class="print-container">
  <ng-container *ngTemplateOutlet="printContent"></ng-container>
</div>
