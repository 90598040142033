import { AfterViewInit, Component, Input } from '@angular/core';
import { IPackage, IProduct } from '../../models/application.interface';
import { PriceFormatTransformPipe } from '../../pipes/price-format.pipe';
import { MatIcon } from '@angular/material/icon';
import { PaymentMethodTypeEnum } from '../../enums/paymentMethodType.enum';
import { AppRepository } from '../../repositories/app.repository';
import { CustomerService } from '../../services/customer.service';
import { AppService } from '../../services/app.service';

declare let bootstrap: any;

@Component({
  selector: 'app-package-price-summary',
  standalone: true,
  imports: [PriceFormatTransformPipe, MatIcon],
  templateUrl: './package-price-summary.component.html',
  styleUrl: './package-price-summary.component.scss',
})
export class PackagePriceSummaryComponent implements AfterViewInit {
  @Input() set selectedPackage(value: IPackage) {
    if (value) {
      this.isListBillMode = this.customerService.isListBillModeActive();
      this.calculatePrices(value, false);
    }
  }
  @Input() currentPaymentMethod: PaymentMethodTypeEnum;
  @Input() set separateLineForFee(value: boolean) {
    if (value && this.customerService.getAppId()) {
      setTimeout(() => {
        this.appRepository.getApplication(this.customerService.getAppId(), false).subscribe(res => {
          const selectedPackage = res.packages.find(item => item.id === res.selectedPackageId);
          if (selectedPackage) {
            this.calculatePrices(selectedPackage, value);
            this.showCardFeeLine = value;
          }
        });
      }, 500);
    } else {
      this.showCardFeeLine = false;
    }
  }
  paymentMethods = PaymentMethodTypeEnum;
  products: IProduct[];
  selectedPackageData: IPackage;
  showCardFeeLine: boolean;
  isListBillMode: boolean;

  constructor(
    private appRepository: AppRepository,
    private customerService: CustomerService,
    private appService: AppService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
    }, 500);
  }

  calculatePrices(packageItem: IPackage, calculateCardFee: boolean) {
    this.products = [];
    packageItem.companies.forEach(company => {
      company.products.forEach(product => {
        this.products.push(product);
      });
    });
    packageItem.totalPrice = this.appService.generatePackageTotalPrice(packageItem, this.isListBillMode);
    if (calculateCardFee) {
      packageItem.cardFee = +((packageItem.totalPrice + packageItem.enrolmentFee) * 0.03).toFixed(2);
    }
    this.selectedPackageData = packageItem;
  }
}
