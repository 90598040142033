<div class="d-flex">
  <div #textDiv class="expandable-text me-3" [ngClass]="{ collapsed: !isExpanded, 'list-wrap': !!textList }">
    @if (text) {
      {{ text }}
    }
    @if (textList?.length) {
      @for (text of textList; track text) {
        <span>{{ text }}</span>
      }
    }
  </div>
  @if (showExpandLink) {
    <a href="javascript:void(0)" class="expand-link" (click)="isExpanded = !isExpanded">
      {{ isExpanded ? 'Less' : 'More' }}
    </a>
  }
</div>
