import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor() {}
  checkout;

  async initStripe(clientSecret: string) {
    const stripe = await loadStripe(environment.stripePublishKey);
    this.checkout = await stripe.initEmbeddedCheckout({ clientSecret: clientSecret });
    this.checkout.mount('#cardPayment');
  }

  destroyStripe() {
    if (this.checkout) {
      this.checkout.destroy();
    }
  }
}
