import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appControlTrimFormat]',
  standalone: true,
})
export class ControlTrimFormatDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('blur', ['$event.target.value'])
  onInput(value: string): void {
    this.ngControl.control?.setValue(value.trim(), { emitEvent: false });
  }
}
