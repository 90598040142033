import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAgent } from '../models/common.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgentRepository {
  constructor(private http: HttpClient) {}

  getAgentInfo(id: string): Observable<IAgent> {
    const url = `${environment.apiUrl}/api/v1/agent/${id}`;
    return this.http.get<IAgent>(url);
  }
}
