@if (selectedPackageData) {
  <div class="summary-block d-flex justify-content-center">
    <div class="summary-block-content d-flex flex-column align-items-center">
      @for (product of products; track product.name) {
        <div class="summary-item mb-1">
          <div class="me-5">{{ product.name }}</div>
          <div>{{ product.price | priceFormat }}</div>
        </div>
      }
      <div class="divider w-100 mb-1"></div>
      <div class="summary-item mb-1">
        <div class="me-5 text-bolder">Total Monthly Cost</div>
        <div class="text-bolder">{{ selectedPackageData.totalPrice | priceFormat }}/mo.</div>
      </div>
      <div class="summary-item mb-1">
        <div class="me-5">One-time service fee</div>
        <div>{{ selectedPackageData.enrolmentFee | priceFormat }}</div>
      </div>
      @if (showCardFeeLine) {
        <div class="summary-item mb-1">
          <div class="me-5">Surcharge 3% card payment fee</div>
          <div>{{ selectedPackageData.cardFee | priceFormat }}</div>
        </div>
      }
      @if (isListBillMode) {
        <div class="summary-item pt-4 mb-1">
          <div class="me-5 text-bolder">Cost per pay period</div>
          <div class="text-bolder">{{ selectedPackageData.pricePerPeriod | priceFormat }}</div>
        </div>
      }
    </div>
    <div
      class="info-block"
      [hidden]="isListBillMode || showCardFeeLine || currentPaymentMethod !== paymentMethods.CARD">
      <mat-icon
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-custom-class="app-tooltip"
        title="Prices shown include a 3% surcharge for credit card payments. Save by using bank draft.">
        info_outlined
      </mat-icon>
    </div>
  </div>
}
