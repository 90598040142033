<header class="header">
  <div class="d-flex align-items-center justify-content-between">
    <div class="header-logo-container">
      <img class="header-logo" [src]="logoUrl" alt="logo" />
    </div>
    @if (agentData) {
      <div class="agent-info">
        <div>{{ agentData.name }}</div>
        <a class="text-decoration-none" href="tel:{{ agentData.phone }}">{{ agentData.phone }}</a>
        @if (billData) {
          <div>{{ billData.name }}</div>
        }
      </div>
    }
  </div>
</header>
