import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { IAgent, IBill } from '../../models/common.interface';
import { CustomerService } from '../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgOptimizedImage, MatButton, MatIcon, MatIconButton, MatAnchor],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  agentData: IAgent;
  billData: IBill;
  logoUrl: string = 'assets/img/logo.png';
  destroy$ = new Subject<void>();
  constructor(private customerService: CustomerService) {}

  ngOnInit() {
    this.customerService.agentSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.agentData = res;
      this.setLogoUrl(res);
    });
    this.customerService.listBillSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.billData = res;
    });
  }

  setLogoUrl(agent: IAgent) {
    if (agent?.distribution?.logoUrl) {
      this.logoUrl = agent.distribution.logoUrl;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
