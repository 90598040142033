<div>
  <div class="print-header d-flex">
    <div class="left-col">
      <div class="logo-container">
        <img class="print-logo" src="assets/img/logo.png" alt="logo" />
      </div>
      @if (agent) {
        <div class="agent-info">
          <div class="mb-1">Your Agent</div>
          <div class="text-bolder agent-name mb-1">{{ agent.name }}</div>
          <div class="d-flex align-items-center mb-1">
            <span class="material-icons me-1 contact-icon">phone_android</span>
            <div class="agent-phone">{{ agent.phone }}</div>
          </div>
          <div class="d-flex align-items-center">
            <span class="material-icons me-1 contact-icon">mail_outline</span>
            <div class="agent-email text-break">{{ agent.email }}</div>
          </div>
        </div>
      } @else {
        <div class="logo-subtitle">Flexible Solutions that Fit You</div>
      }
    </div>

    <div class="right-col">
      <div class="d-flex w-100">
        <div class="qr-label flex-fill d-flex align-items-center justify-content-center">
          Continue Enrollment from the place <br />
          you've stopped by scanning QR code
        </div>
        <div class="qr-container d-flex align-items-center justify-content-center">
          <qrcode [qrdata]="appUrl" [width]="130"></qrcode>
        </div>
      </div>
    </div>
  </div>
</div>
