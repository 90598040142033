import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { GlobalMessagesComponent } from '../components/global-messages/global-messages.component';

export type GlobalMessageType = 'error' | 'success';

@Injectable({
  providedIn: 'root',
})
export class GlobalMessagesService {
  snackBarRef: MatSnackBarRef<any>;
  constructor(private snackBar: MatSnackBar) {}

  show(type: GlobalMessageType, message: string) {
    const config: MatSnackBarConfig = {
      duration: 3000,
      panelClass: ['snack-bar-container'],
      verticalPosition: 'top',
      data: {
        message: message,
        type: type,
        dismiss: () => this.snackBarRef.dismiss(),
      },
    };

    this.snackBarRef = this.snackBar.openFromComponent(GlobalMessagesComponent, config);
  }

  hide() {
    this.snackBarRef.dismiss();
  }
}
