import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IStepState } from '../../../../shared/models/common.interface';
import { Subject, takeUntil } from 'rxjs';
import { StepperService } from '../../../../shared/services/stepper.service';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { ApplicationView, IPackage } from '../../../../shared/models/application.interface';
import { cloneDeep, find } from 'lodash-es';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { PriceFormatTransformPipe } from '../../../../shared/pipes/price-format.pipe';
import { DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { PrintPageHeaderComponent } from '../../../../shared/components/print-page-header/print-page-header.component';
import { PrintService } from '../../../../shared/services/print.service';
import { getStateByZipCode } from '../../../../shared/utils/state-by-zipcode';
import { CustomerService } from '../../../../shared/services/customer.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { HeartIconToggleComponent } from '../../../../shared/components/heart-icon-toggle/heart-icon-toggle.component';
import { PackageRepository } from '../../../../shared/repositories/package.repository';

@Component({
  selector: 'app-insurance-plan-step',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    MatButton,
    CurrencyPipe,
    PriceFormatTransformPipe,
    NgTemplateOutlet,
    PrintPageHeaderComponent,
    NgClass,
    MatSlideToggle,
    FormsModule,
    HeartIconToggleComponent,
  ],
  templateUrl: './insurance-plan-step.component.html',
  styleUrl: './insurance-plan-step.component.scss',
})
export class InsurancePlanStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('printContent') printContentRef: TemplateRef<any>;
  @ViewChild('printIframe') printIframeRef: ElementRef;
  @Input() set packagesList(value: IPackage[]) {
    if (value) {
      this.packagesListData = value;
      this.packagesListData.forEach(packageItem => {
        this.setPricesForDisplay(packageItem);
      });
      this.filterPackages(this.packagesListData);
    }
  }
  @Input() set selectedPackage(value: IPackage) {
    this.selectedPackageData = value;
    if (value) {
      this.setPricesForDisplay(value);
    }
    this.filterPackages(this.packagesListData);
  }
  @Input() set quote(value: ApplicationView) {
    if (value) {
      this.quoteData = value;
      this.currentState = getStateByZipCode(+value.contactInfo.zipCode);
    }
  }
  @Output() selectPackageEvent: EventEmitter<IPackage> = new EventEmitter<IPackage>();
  stepState: IStepState;
  currentState: string;
  packagesListData: IPackage[];
  packagesListDataFiltered: IPackage[];
  selectedPackageData: IPackage;
  quoteData: ApplicationView;
  isListBillMode: boolean;
  isAgentMode: boolean;
  packagesFilterValue: string;
  isLockedMode: boolean;
  showAllPlans: boolean = false;
  destroy$ = new Subject<void>();
  private printIframe;
  private portalHost: PortalOutlet;
  constructor(
    private stepperService: StepperService,
    private viewContainerRef: ViewContainerRef,
    private printService: PrintService,
    private customerService: CustomerService,
    private packageRepository: PackageRepository,
  ) {}

  ngOnInit() {
    this.isLockedMode = this.customerService.isLockedModeActive();
    this.isListBillMode = this.customerService.isListBillModeActive();
    this.packagesFilterValue = this.customerService.getProductsFilter();
    this.isAgentMode = this.customerService.isAgentModeActive();
    this.filterPackages(this.packagesListData);
    this.setStepState();
  }

  ngAfterViewInit() {
    this.preparePrintIframe();
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = cloneDeep(res[1]);
    });
  }

  onSelectPackageClick(packageItem: IPackage) {
    this.selectPackageEvent.emit(packageItem);
  }

  onPrintListClick(e) {
    e.stopPropagation();
    this.portalHost = new DomPortalOutlet(this.printIframe.contentDocument.body);
    const portal = new TemplatePortal(this.printContentRef, this.viewContainerRef, {
      packagesListData: this.packagesListDataFiltered,
    });
    this.portalHost.attach(portal);
    this.printIframe.contentWindow.onafterprint = () => {
      this.printIframe.contentDocument.body.innerHTML = '';
    };
    this.printService.print(this.printIframe);
  }

  onShowAllPlansToggle() {
    this.filterPackages(this.packagesListData);
  }

  togglePackageAsFavorite(packageItem: IPackage) {
    this.packageRepository
      .setFavoritePackage(packageItem.id, this.customerService.getAgentId(), !packageItem.isFavorite)
      .subscribe(() => {
        const item = this.packagesListData.find(i => i.id === packageItem.id);
        if (item) {
          item.isFavorite = !item.isFavorite;
        }
        this.filterPackages(this.packagesListData);
      });
  }

  onSelectedProductClick(packageItem: IPackage) {
    if (this.selectedPackageData?.id === packageItem.id) {
      this.stepperService.openStep(2);
    }
  }

  private preparePrintIframe() {
    this.printIframe = this.printIframeRef.nativeElement;
    this.printService.attachStyles(this.printIframe.contentWindow);
  }

  private setPricesForDisplay(packageItem: IPackage) {
    packageItem.price = 0;
    packageItem.pricePerPeriod = 0;
    packageItem.companies.forEach(company => {
      company.products.forEach(product => {
        packageItem.price += product.price;
        if (this.isListBillMode) {
          packageItem.pricePerPeriod += product.pricePerPeriod;
        }
        product.benefits.forEach(benefit => {
          benefit.amount = find(benefit.levels, level => level.isSelected).amount;
        });
      });
    });
    packageItem.price = +packageItem.price.toFixed(2);
    if (this.isListBillMode) {
      packageItem.pricePerPeriod = +packageItem.pricePerPeriod.toFixed(2);
    }
  }

  private filterPackages(packages: IPackage[]) {
    if (this.isLockedMode) {
      if (this.selectedPackageData) {
        this.packagesListDataFiltered = [this.selectedPackageData];
      }
    } else {
      this.packagesListDataFiltered = cloneDeep(packages);
      if (this.packagesFilterValue) {
        this.filterByUrlParams(packages);
      } else {
        this.filterByAgeRange(packages);
        if (this.isAgentMode) {
          this.sortFavoritePackages();
        }
        if (this.selectedPackageData) {
          this.moveSelectedPackageOnFirstPlace();
        }
      }
    }
  }

  private filterByUrlParams(packages: IPackage[]) {
    if (packages?.length && !this.isAgentMode) {
      this.packagesListDataFiltered = packages.filter(packageItem =>
        this.packagesFilterValue.includes(packageItem.altName.toLowerCase())
      );
    }
  }

  private filterByAgeRange(packages: IPackage[]) {
    if (!this.showAllPlans && this.quoteData) {
      const customerAge = this.calculateCustomerAge(
        this.quoteData?.startDate,
        this.quoteData?.customers?.[0]?.dateOfBirth
      );
      this.packagesListDataFiltered = packages.filter(packageItem => {
        if (this.isAgentMode) {
          return packageItem.isFavorite || (customerAge >= packageItem.minAge && customerAge <= packageItem.maxAge);
        } else {
          return customerAge >= packageItem.minAge && customerAge <= packageItem.maxAge;
        }
      });
    }
  }

  private sortFavoritePackages() {
    this.packagesListDataFiltered.sort((a, b) => {
      if (a.isFavorite && !b.isFavorite) return -1;
      if (!a.isFavorite && b.isFavorite) return 1;
      return a.price - b.price;
    });
  }

  private moveSelectedPackageOnFirstPlace() {
    const selectedProductIndex = this.packagesListDataFiltered.findIndex(i => i.id === this.selectedPackageData.id);
    if (selectedProductIndex === -1) {
      this.packagesListDataFiltered.unshift(this.selectedPackageData);
    } else if (selectedProductIndex !== 0) {
      this.packagesListDataFiltered.splice(selectedProductIndex, 1);
      this.packagesListDataFiltered.unshift(this.selectedPackageData);
    }
  }

  private calculateCustomerAge(startDate: string, birthOfDate: string) {
    const birthDate = new Date(birthOfDate);
    const insuranceStartDate = new Date(startDate);
    let age = insuranceStartDate.getFullYear() - birthDate.getFullYear();
    if (
      insuranceStartDate.getMonth() < birthDate.getMonth() ||
      (insuranceStartDate.getMonth() === birthDate.getMonth() && insuranceStartDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
