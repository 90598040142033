<footer class="footer">
  <div
    class="footer-top d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
    <ul class="footer-links d-flex flex-column flex-md-row align-items-center justify-content-center p-0 mb-3 mb-md-0">
      <li class="footer-link-container me-0 me-md-4 mb-3 mb-md-0">
        <a href="https://flexbenefits.co/terms-and-conditions" target="_blank" class="footer-link">
          Terms and Conditions
        </a>
      </li>
      <li class="footer-link-container me-0 me-md-4 mb-3 mb-md-0">
        <a href="https://flexbenefits.co/privacy-policy" target="_blank" class="footer-link">Privacy Policy</a>
      </li>
      <li class="footer-link-container me-0 me-md-4 mb-3 mb-md-0">
        <a href="https://www.flexbenefits.co/contact" target="_blank" class="footer-link">Contact</a>
      </li>
    </ul>
    <img class="footer-logo" src="assets/img/footer-logo.png" alt="footer-logo" />
  </div>
  <div class="footer-bottom">
    <div class="text-center">
      <p class="m-0">Copyright © 2024 FLEXBENEFITS.CO, LLC</p>
      <p class="m-0">866-299-3539</p>
    </div>
  </div>
</footer>
