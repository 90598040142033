<mat-expansion-panel
  [disabled]="!stepState.isEnabled"
  hideToggle="true"
  [(expanded)]="stepState.isOpened"
  (opened)="onPaymentStepOpen()">
  <mat-expansion-panel-header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <mat-panel-title>
          <span>Step 5</span>
          <span class="dot-divider"></span>
          <span>{{ stepState.stepName }}</span>
        </mat-panel-title>
        @if (stepState.isCompleted) {
          <div class="complete-icon">
            <mat-icon>check</mat-icon>
          </div>
        }
      </div>
      @if (stepState.isCompleted) {
        <div class="d-flex align-items-center pt-1 step-completeness-info">
          <mat-icon class="me-2">paid</mat-icon>
          <div>
            <span>Payment was submitted.</span>
          </div>
        </div>
      }
    </div>
  </mat-expansion-panel-header>
  <div class="payment-content">
    <div class="d-flex flex-column container pt-4 pb-4">
      @if (!isPayedByACH && !isPayedByCard && !isPayedByListBill) {
        <div class="d-flex flex-wrap">
          <div class="d-flex flex-column mb-4 purchase-subtitle">
            <span class="package-title">{{ selectedPackageData?.name }}</span>
            <span class="package-sub-title">{{ selectedPackageData?.description }}</span>
            <app-plan-start-date-section
              *ngIf="startDateControl?.value"
              [isReadOnly]="isLockedMode"
              [control]="startDateControl"
              [isUpdateImmediately]="true"
              (updateStartDateEvent)="updateStartDate($event)">
            </app-plan-start-date-section>
          </div>
          @if (stepState.isOpened) {
            <div class="price-summary-container">
              <app-package-price-summary
                [separateLineForFee]="paymentMethodControl?.value === paymentMethodType.CARD"
                [currentPaymentMethod]="currentPaymentMethod"
                [selectedPackage]="selectedPackageData">
              </app-package-price-summary>
            </div>
          }
        </div>
        @if (!isListBillMode) {
          <div class="d-flex">
            <div class="section-payment-methods">
              <mat-radio-group
                (change)="onPaymentMethodChange($event.value)"
                aria-label="Select a payment method option"
                class="d-flex mb-4 section-select-method"
                [formControl]="paymentMethodControl">
                @if (!isListBillMode) {
                  <mat-radio-button [value]="paymentMethodType.CARD">
                    <div class="d-flex flex-column payment-method-content">
                      <mat-icon class="payment-method-icon">credit_card</mat-icon>
                      <span>Card</span>
                      <span class="text-small text-light-gray"> Surcharge of 3% is added to the regular payments </span>
                    </div>
                  </mat-radio-button>
                }
                <mat-radio-button [value]="paymentMethodType.ACH">
                  <div class="d-flex flex-column payment-method-content">
                    <mat-icon class="payment-method-icon">account_balance</mat-icon>
                    <span>Bank account (ACH)</span>
                    <span class="text-small text-light-gray"> No Surcharge </span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>

              @if (paymentMethodControl.value === paymentMethodType.ACH) {
                <form class="d-flex flex-column ach-payment-form" [formGroup]="achPaymentForm">
                  <mat-form-field class="mb-2" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="email" />
                  </mat-form-field>

                  <mat-form-field class="mb-2" appearance="outline">
                    <mat-label>Account Holder Name</mat-label>
                    <input type="text" matInput formControlName="accountHolderName" />
                    @if (achPaymentForm.controls.accountHolderName.errors?.required) {
                      <mat-error>Account Holder Name is required.</mat-error>
                    }
                    @if (achPaymentForm.controls.accountHolderName.errors?.pattern) {
                      <mat-error>Only characters, spaces, -, ' are allowed.</mat-error>
                    }
                  </mat-form-field>

                  <div class="payment-instruction d-lg-none">
                    <img src="assets/img/bank-payment-instruction.png" alt="payment instruction" />
                  </div>

                  <mat-form-field class="mb-2" appearance="outline">
                    <mat-label>Your Bank Name</mat-label>
                    <input type="text" matInput formControlName="bankName" />
                    @if (achPaymentForm.controls.bankName.errors?.required) {
                      <mat-error>Bank Name is required.</mat-error>
                    }
                    @if (achPaymentForm.controls.bankName.errors?.pattern) {
                      <mat-error>Only characters, spaces, &, -, ', . are allowed.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="mb-2" appearance="outline">
                    <mat-label>Routing Number</mat-label>
                    <input type="text" matInput formControlName="routingNumber" />
                    @if (achPaymentForm.controls.routingNumber.errors?.required) {
                      <mat-error>Routing Number is required.</mat-error>
                    }
                    @if (achPaymentForm.controls.routingNumber.errors?.invalidRoutingNumber) {
                      <mat-error>Routing Number is invalid.</mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="mb-2" appearance="outline">
                    <mat-label>Account Number</mat-label>
                    <input type="text" matInput formControlName="accountNumber" />
                    @if (achPaymentForm.controls.accountNumber.errors?.required) {
                      <mat-error>Account Number is required.</mat-error>
                    }
                    @if (achPaymentForm.controls.accountNumber.errors?.pattern) {
                      <mat-error>Only numbers are allowed.</mat-error>
                    }
                  </mat-form-field>

                  <div class="mb-4">
                    <mat-checkbox formControlName="achPaymentDelay"></mat-checkbox>
                    <span>delay ACH payment process until the policy effective date</span>
                  </div>

                  <button
                    id="bankPaymentBtn"
                    mat-raised-button
                    color="primary"
                    class="btn-lg bank-payment-btn"
                    type="button"
                    [disabled]="achPaymentForm.invalid"
                    (click)="bankPaymentClick()">
                    <div>Pay</div>
                    <div>{{ selectedPackageData?.totalPrice + selectedPackageData?.enrolmentFee | priceFormat }}</div>
                  </button>
                </form>
              }

              <div [hidden]="paymentMethodControl.value !== paymentMethodType.CARD">
                <div class="card-payment-container" id="cardPayment"></div>
              </div>
            </div>
            @if (paymentMethodControl.value === paymentMethodType.ACH) {
              <div class="payment-instruction w-50 align-self-end d-none d-lg-block">
                <img src="assets/img/bank-payment-instruction.png" alt="payment instruction" />
              </div>
            }
          </div>
        } @else {
          <div>
            <div class="bill-disclaimer pt-5 mb-4">
              <p>I hereby elect the list bill payment option for my premiums and membership association dues where payment will be made by my employer on my behalf.   As the primary applicant and member, I understand and agree that:</p>
              <ul>
                <li>I am authorizing my employer to submit monthly payments for myself and any dependents (if applicable).</li>
                <li>The monthly list bill statement will be sent directly to my employer.</li>
                <li>Flex Benefits, Companion Life Insurance Company, and the Communicating for America association have no obligation to guarantee continuation of insurance benefits or any other liability in the event that membership lapses due to the employer’s failure to submit payment by the due date.</li>
                <li>
                  <div class="mb-1">I may cease to participate in the list bill Authorization upon giving 30-day prior notice and may send future payments directly to the benefits administrator:</div>
                  <div class="ps-5">
                    <div class="mb-1">The Loomis Company</div>
                    <div class="mb-1">
                      <a href="installations@Loomislive.com">{{'installations@Loomislive.com'}}</a>
                    </div>
                    <div class="mb-1">850 N Park Rd., Wyomissing, PA 19610 </div>
                    <div class="mb-1">or call 1-877-960-1343</div>
                  </div>
                </li>
                <li>Should the list bill Authorization terminate or I am no longer eligible for the employer list bill, the Administrator will require that a monthly automatic bank withdrawal or credit card charge be arranged for payment to avoid loss of insurance coverage or other non-insurance services.</li>
                <li>The membership and insurance benefits are neither intended nor anticipated to be an employer-sponsored plan.</li>
              </ul>
            </div>
            <div>
              <button
                id="groupPaymentBtn"
                mat-raised-button
                color="primary"
                class="btn-lg group-payment-btn"
                type="button"
                (click)="billPaymentClick()">
                <div>Accept and Submit</div>
              </button>
            </div>
          </div>
        }
      }
      @if (isPayedByCard || isPayedByACH || isPayedByListBill) {
        <div class="success-payment-block">
          <mat-icon class="mb-1">account_balance</mat-icon>
          <div class="success-payment-title mb-2">Your enrollment was submitted.</div>
          <div class="success-payment-description">
            <div>
              You have successfully completed your enrollment. You will receive an email with a receipt and a summary of
              your order.
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</mat-expansion-panel>
