import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { convertStringToLocalDate } from '../utils/date-format';

@Directive({
  selector: '[appDateInputMask]',
  standalone: true,
})
export class DateInputFormatDirective {
  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event.target.value']) onInputChange(value: string): void {
    let input = value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, '');

    // Format as MM/DD/YYYY if the length is 8 (valid date input)
    if (input.length === 8) {
      const month = input.substring(0, 2);
      const day = input.substring(2, 4);
      const year = input.substring(4, 8);

      const formattedDate = `${year}-${month}-${day}`;

      this.control.control.setValue(convertStringToLocalDate(formattedDate));
      this.control.control.markAsTouched();
      this.control.control.updateValueAndValidity();
    }
  }
}
