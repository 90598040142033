import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ICalculatePackagePayload, IPackage } from '../models/application.interface';

@Injectable({
  providedIn: 'root',
})
export class PackageRepository {
  constructor(private http: HttpClient) {}

  calculatePackages(requestBody: ICalculatePackagePayload): Observable<IPackage[]> {
    const url = `${environment.apiUrl}/api/v1/package/calculate`;
    return this.http.post<IPackage[]>(url, requestBody);
  }

  calculatePackage(id: string, requestBody: ICalculatePackagePayload): Observable<IPackage> {
    const url = `${environment.apiUrl}/api/v1/package/${id}/calculate`;
    return this.http.post<IPackage>(url, requestBody);
  }

  setFavoritePackage(id: string, agentId: string, isFavorite: boolean) {
    const url = `${environment.apiUrl}/api/v1/package/${id}/setfavorite/${agentId}/${isFavorite}`;
    return this.http.get(url);
  }
}
