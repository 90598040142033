import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EnvironmentSettings, IBill } from '../models/common.interface';
import {
  ApplicationView,
  ApplicationViewPayload,
  IBankPaymentPayload,
  IBankPaymentResponse,
} from '../models/application.interface';
import { environment } from '../../../environments/environment';
import { GlobalMessagesService } from '../services/global-messages.service';

@Injectable({
  providedIn: 'root',
})
export class AppRepository {
  constructor(
    private http: HttpClient,
    private messageService: GlobalMessagesService
  ) {}

  getEnvSettings(): Observable<EnvironmentSettings> {
    const url = `/assets/appSettings.json`;
    return this.http.get<EnvironmentSettings>(url);
  }

  getApplication(id: string, includeCreditCardSurcharge: boolean): Observable<ApplicationView> {
    const url = `${environment.apiUrl}/api/v1/application/${id}`;
    return this.http.get<ApplicationView>(url, { params: { includeCreditCardSurcharge: includeCreditCardSurcharge } });
  }

  updateQuote(params: ApplicationViewPayload, id: string): Observable<ApplicationView> {
    const url = `${environment.apiUrl}/api/v1/application/${id}`;
    return this.http.put<ApplicationView>(url, params);
  }

  createQuote(params: ApplicationViewPayload): Observable<ApplicationView> {
    const url = `${environment.apiUrl}/api/v1/application`;
    return this.http.post<ApplicationView>(url, params);
  }

  sendApplicationQuoteLinkByMail(applicationId: string) {
    const url = `${environment.apiUrl}/api/v1/application/sendApplicationQuoteLinkByMail`;
    return this.http.get(url, { params: { applicationId: applicationId } }).pipe(
      map(() => {
        this.messageService.show('success', 'Email was sent!');
      })
    );
  }

  sendApplicationQuoteLinkBySMS(applicationId: string) {
    const url = `${environment.apiUrl}/api/v1/application/sendApplicationQuoteLinkBySms`;
    return this.http.get(url, { params: { applicationId: applicationId } }).pipe(
      map(() => {
        this.messageService.show('success', 'SMS was sent!');
      })
    );
  }

  sharePackageLinkByMail(applicationId: string, emailToShare: string) {
    const url = `${environment.apiUrl}/api/v1/application/sendApplicationSharedQuoteLinkByMail`;
    return this.http.get(url, { params: { applicationId: applicationId, emailToShare: emailToShare } });
  }

  getAcceptanceRules(packageId: string[], documentType: number, currentState: string): Observable<string> {
    const url = `${environment.apiUrl}/api/v1/agreement/${documentType}/agreement-text/${currentState}`;
    return this.http.post(url, packageId, { responseType: 'text' });
  }

  getPreviewCompleteAgreement(params, documentType: number) {
    const url = `${environment.apiUrl}/api/v1/agreement/${documentType}/preview`;
    return this.http.post(url, params, { responseType: 'blob', observe: 'response' });
  }

  payByACH(appId: string, data: IBankPaymentPayload) {
    const url = `${environment.apiUrl}/api/v1/application/${appId}/payment/ach`;
    return this.http.post<IBankPaymentResponse>(url, data);
  }

  creditCardPaymentInit(appId: string) {
    const url = `${environment.apiUrl}/api/v1/application/${appId}/payment/cc`;
    return this.http.post(url, {}, { responseType: 'text' });
  }

  payByListBill(appId: string) {
    const url = `${environment.apiUrl}/api/v1/application/${appId}/payment/listbill`;
    return this.http.post(url, {}, { responseType: 'text' });
  }

  getListBillData(billId: string): Observable<IBill> {
    const url = `${environment.apiUrl}/api/v1/ListBill/${billId}`;
    return this.http.get<IBill>(url);
  }

  getFullUrl(shortUrl: string): Observable<any> {
    const url = `${environment.apiUrl}/api/v1/a/${shortUrl}`;
    return this.http.get(url, { responseType: 'text' });
  }
}
