<div class="snack-bar" [ngClass]="type === 'error' ? 'snack-bar-error' : 'snack-bar-success'">
  @if (type === 'error') {
    <div class="d-flex align-items-center justify-content-between h-100">
      <div class="d-flex align-items-center me-3">
        <mat-icon class="me-3" aria-hidden="false" aria-label="Exit Icon" fontIcon="highlight_off"></mat-icon>
        <span>{{ message }}</span>
      </div>
      <div class="d-flex align-items-center h-100">
        @if (showReloadBtn) {
          <button type="button" class="reload-btn me-4" (click)="reload()">RELOAD</button>
        }
        <button type="button" mat-icon-button class="close-btn" (click)="close()">
          <mat-icon aria-hidden="false" aria-label="Exit Icon" fontIcon="close"></mat-icon>
        </button>
      </div>
    </div>
  }
  @if (type === 'success') {
    <div class="d-flex align-items-center justify-content-between h-100">
      <div class="d-flex align-items-center me-3">
        <mat-icon class="me-3" aria-hidden="false" aria-label="Exit Icon" fontIcon="check_circle_outline"></mat-icon>
        <span>{{ message }}</span>
      </div>
      <button type="button" mat-icon-button class="close-btn" (click)="close()">
        <mat-icon aria-hidden="false" aria-label="Exit Icon" fontIcon="close"></mat-icon>
      </button>
    </div>
  }
</div>
