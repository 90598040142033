<div [formGroup]="customer">
  <div
    class="d-flex justify-content-between customer-title"
    [class.customer-title-full-data]="type === currentType.FULL_DATA">
    @if (type === currentType.FULL_DATA && currentCustomerType === customerType.FORM_SUBMITTER) {
      <span class="customer-label">Primary Participant Info</span>
    }
    @if (type === currentType.FULL_DATA && currentCustomerType === customerType.SPOUSE) {
      <span class="customer-label">Spouse or Domestic Partner Info</span>
    }
    @if (
      type === currentType.ENTRY_DATA || (type === currentType.FULL_DATA && currentCustomerType === customerType.CHILD)
    ) {
      <span class="customer-label">{{ labelTitle }}</span>
    }
    @if (type === currentType.ENTRY_DATA && !isReadOnly) {
      <span class="remove-btn" (click)="removeSelectedControl(index)">Remove</span>
    }
  </div>

  <div class="d-flex align-items-start customer-control" [class.full-customer-control]="type === currentType.FULL_DATA">
    @if (type === currentType.FULL_DATA) {
      <mat-form-field class="mb-2" appearance="outline" class="name-field">
        <mat-label>First Name</mat-label>
        <input type="text" matInput appControlTrimFormat formControlName="firstName" (blur)="checkHolderNameChange()" />
        @if (customer.controls.firstName.errors?.required) {
          <mat-error>First Name is required.</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="mb-2" appearance="outline" class="name-field">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput appControlTrimFormat formControlName="lastName" (blur)="checkHolderNameChange()" />
        @if (customer.controls.lastName.errors?.required) {
          <mat-error>Last Name is required.</mat-error>
        }
      </mat-form-field>
    }

    <mat-form-field appearance="outline" class="birth-date-field">
      <mat-label>Date of Birth</mat-label>
      <input
        matInput
        appDateInputMask
        [matDatepickerFilter]="ageFilter"
        [matDatepicker]="customerBirthDatePicker"
        formControlName="dateOfBirth" />

      @if (customer.controls.dateOfBirth.errors?.required) {
        <mat-error>Date of Birth is required.</mat-error>
      }
      @if (customer.controls.dateOfBirth.errors?.ageExceeded) {
        <mat-error>Dependents over 26 are not allowed.</mat-error>
      }
      @if (customer.controls.dateOfBirth.errors?.ageHigh) {
        <mat-error>Only person under 72 years is allowed.</mat-error>
      }
      <mat-datepicker-toggle matIconSuffix [for]="customerBirthDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #customerBirthDatePicker></mat-datepicker>
      <mat-hint>MM/DD/YYYY</mat-hint>
    </mat-form-field>

    <mat-button-toggle-group formControlName="genderType" class="toggle">
      <mat-button-toggle [value]="genderType.MALE" class="flex-fill">
        <mat-icon [class.visible]="customer.get('genderType').value === 1">check</mat-icon>
        Male
      </mat-button-toggle>
      <mat-button-toggle [value]="genderType.FEMALE" class="flex-fill">
        <mat-icon [class.visible]="customer.get('genderType').value === 2">check</mat-icon>
        Female
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
