@if (!isStartDateExpired) {
  <div class="d-flex align-items-center insurance-start-date-panel">
    <span>Plan starts on </span>
    <span class="selected-start-date">{{ control?.value | date: 'MM/dd/yyyy' }}</span>
    @if (!isReadOnly) {
      <div class="changes-section">
        <button
          mat-button
          color="primary"
          class="btn-lg change-btn"
          type="button"
          (click)="openInsuranceStartDateDialog()">
          Change
        </button>
      </div>
    }
  </div>
}

<ng-template #startDateDialog>
  <app-modal-dialog>
    @if (isOpenByDefault && isLockedMode) {
      <div class="my-4 dialog-content-description d-flex flex-column align-items-center">
        <div class="fw-bold">Your application has expired.</div>
        <div class="fw-bold">Please contact your agent.</div>
      </div>
    } @else {
      <div class="d-flex flex-column align-items-center justify-content-between pb-3">
        <div class="mb-4 dialog-content-description">
          @if (!invalidInputDate) {
            <span>A new start date may update Products prices and total Sum.</span>
          } @else {
            <span>The insurance start date can't be in the past. Please update the Start date to continue.</span>
            <span>A new start date may update Products prices and total Sum.</span>
          }
        </div>

        <mat-form-field class="mb-4 start-date-field" appearance="outline">
          <mat-label>Insurance Start Date (Effective date)</mat-label>
          <input
            matInput
            appDateInputMask
            [matDatepickerFilter]="isListBillMode ? billFilter : rangeFilter"
            [matDatepicker]="startDatePicker"
            [formControl]="control" />
          @if (control?.errors?.startDateRangeError) {
            <mat-error>
              The allowed Start Date range is from the
              <span class="text-bolder">Next Day to +62 days</span>
              from the actual date.
            </mat-error>
          }
          <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-hint>MM/DD/YYYY</mat-hint>
        </mat-form-field>

        <div class="w-100 d-flex justify-content-between pb-4 actions-buttons">
          <button
            type="button"
            mat-flat-button
            color="primary"
            class="btn-sm"
            [class.update-btn-full-width]="invalidInputDate"
            [disabled]="control?.invalid"
            [mat-dialog-close]="true">
            Update Date
          </button>
          @if (!invalidInputDate) {
            <button type="button" mat-flat-button class="btn-sm" (click)="closeInsuranceStartDateDialog()">
              Cancel
            </button>
          }
        </div>
      </div>
    }
  </app-modal-dialog>
</ng-template>
