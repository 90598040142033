import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { LoaderService } from '../services/loader.service';
import { inject } from '@angular/core';
import { catchError, finalize, throwError } from 'rxjs';
import { GlobalMessagesService } from '../services/global-messages.service';

let requestsCount = 0;

export const httpRequestInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const loaderService = inject(LoaderService);
  const messagesService = inject(GlobalMessagesService);
  let modifiedReq: HttpRequest<unknown>;
  requestsCount++;

  if (!req.params.get('skipLoader') || req.params.get('skipLoader') === 'false') {
    loaderService.show();
  }

  if (req.params.get('skipLoader')) {
    modifiedReq = req.clone({
      params: req.params.delete('skipLoader'),
    });
  }

  return next(modifiedReq ? modifiedReq : req).pipe(
    catchError(err => {
      messagesService.show('error', 'Server error has occurred.');
      return throwError(() => err);
    }),
    finalize(() => {
      if (requestsCount > 0) {
        requestsCount--;
      }
      if (requestsCount === 0) {
        loaderService.hide();
      }
    })
  );
};
