import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { AppRepository } from '../../shared/repositories/app.repository';
import { ApplicationView, IPackage } from '../../shared/models/application.interface';
import { PackageContentComponent } from '../../shared/components/package-content/package-content.component';
import { PackagePriceSummaryComponent } from '../../shared/components/package-price-summary/package-price-summary.component';
import { getStateByZipCode } from '../../shared/utils/state-by-zipcode';
import { AppService } from '../../shared/services/app.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';
import { DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { PrintService } from '../../shared/services/print.service';
import { CustomerService } from '../../shared/services/customer.service';

@Component({
  selector: 'app-shared-package',
  standalone: true,
  imports: [PackageContentComponent, PackagePriceSummaryComponent, MatButton, MatIcon, NgTemplateOutlet],
  templateUrl: './shared-package.component.html',
  styleUrl: './shared-package.component.scss',
})
export class SharedPackageComponent implements OnInit, AfterViewInit {
  @ViewChild('printContent') printContentRef: TemplateRef<any>;
  @ViewChild('printIframe') printIframeRef: ElementRef;
  appId: string;
  quote: ApplicationView;
  package: IPackage;
  currentState: string;
  isListBillMode: boolean;
  private printIframe;
  private portalHost: PortalOutlet;
  constructor(
    private route: ActivatedRoute,
    private appRepository: AppRepository,
    private appService: AppService,
    private printService: PrintService,
    private viewContainerRef: ViewContainerRef,
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    this.isListBillMode = this.customerService.isListBillModeActive();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.appId = params['applicationId'];
      this.appRepository.getApplication(this.appId, true).subscribe(res => {
        this.quote = res;
        this.package = this.quote.packages.find(packageItem => packageItem.id === this.quote.selectedPackageId);
        this.package.totalPrice = this.appService.generatePackageTotalPrice(this.package, this.isListBillMode);
        this.currentState = getStateByZipCode(+this.quote.contactInfo.zipCode);
      });
    });
  }

  ngAfterViewInit() {
    this.preparePrintIframe();
  }

  printPackage() {
    this.portalHost = new DomPortalOutlet(this.printIframe.contentDocument.body);
    const portal = new TemplatePortal(this.printContentRef, this.viewContainerRef, {
      selectedPackageData: this.package,
    });
    this.portalHost.attach(portal);
    this.printIframe.contentWindow.onafterprint = () => {
      this.printIframe.contentDocument.body.innerHTML = '';
    };
    this.printService.print(this.printIframe);
  }

  private preparePrintIframe() {
    this.printIframe = this.printIframeRef.nativeElement;
    this.printService.attachStyles(this.printIframe.contentWindow);
  }
}
