<div class="package-content">
  @if (selectedPackageData) {
    @for (company of selectedPackageData.companies; track company.name) {
      <div class="company-container">
        <div class="title-container d-flex align-items-center justify-content-between">
          <div class="company-name pe-2">{{ company.name }}</div>
          <div class="company-logo">
            <img class="header-logo" [src]="company.logoUrl" alt="company-logo" />
          </div>
        </div>
        @for (product of company.products; track product.name) {
          <div class="mb-4">
            <div class="product-name">{{ product.name }}</div>
            @for (benefit of product.benefits; track benefit.name) {
              <div class="benefit-container d-flex flex-wrap">
                <div class="left-col">
                  <div class="benefit-name mb-1">{{ benefit.name }}</div>
                  <div class="benefit-description">
                    <app-expandable-text [text]="benefit.shortDescription"></app-expandable-text>
                  </div>
                </div>
                <div class="right-col">
                  <div
                    class="benefit-level-control d-flex justify-content-between dropdown"
                    [ngClass]="{ 'read-only': benefit.isOnlyOneLevelAvailable || isReadOnly }">
                    <div class="control-content flex-fill">
                      <div class="level-description mb-1">{{ benefit.selectedLevel.description }}</div>
                      <div class="level-limitation">{{ benefit.selectedLevel.limitation }}</div>
                    </div>
                    @if (!benefit.isOnlyOneLevelAvailable && !isReadOnly) {
                      <div
                        class="action-container"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="dropdownMenuTrigger">
                        <mat-icon class="collapse-icon">keyboard_arrow_up</mat-icon>
                        <button class="edit-btn btn-sm" mat-icon-button color="primary" aria-label="Edit benefit level">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div>
                      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuTrigger">
                        <div class="levels-list">
                          @for (level of benefit.levels; track level.id) {
                            <div
                              class="level-option"
                              [ngClass]="level.isSelected ? 'selected' : ''"
                              (click)="onLevelOptionClick(benefit, level)">
                              <div class="d-flex align-items-center w-100">
                                <div class="icon-wrapper me-2 me-md-3">
                                  @if (level.isSelected) {
                                    <mat-icon>check</mat-icon>
                                  }
                                </div>
                                <div class="d-flex flex-fill align-items-center justify-content-between flex-wrap">
                                  <div>
                                    <div class="level-description mb-1">{{ level.description }}</div>
                                    <div class="level-limitation">{{ level.limitation }}</div>
                                  </div>
                                  @if (!level.isSelected) {
                                    <div class="price-dif">
                                      {{ level.price - benefit.selectedLevel.price | priceFormat: true }}/mo.
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
      <div class="d-flex mb-4 pb-4">
        <app-expandable-text [textList]="company.disclaimers"></app-expandable-text>
      </div>
    }
  }
</div>
